@use '../../abstracts' as *;

/**
* @description Block - Taxation
*
*/

.block-taxation {
  padding: $block-padding-major-xs - 7px 0;

  &__title {
    padding-bottom: $block-padding-minor-xs - 7px;
    text-transform: none;
  }

  &__form {
    margin-bottom: $block-padding-minor-xs - 3px;
  }

  &__subtitle {
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  @include media-breakpoint-up(sm) {
    padding: $block-padding-major-sm - 6px 0 $block-padding-major-sm;

    &__title {
      padding-bottom: $block-padding-minor-sm - 8px;
    }

    &__form {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    &__form {
      margin-right: 20px;
    }

    &__subtitle {
      margin-top: -2px;
    }
  }
}
