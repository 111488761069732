@use '../abstracts' as *;

input {
  margin: 0;
  color: $color-black;
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &::placeholder {
    color: #757575;
    opacity: 1;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
}

input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  margin-top: 1px\9; /* stylelint-disable-line */
  line-height: normal;

  &:focus {
    outline: dotted thin;
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.form {
  input[type='checkbox'] {
    + .check {
      position: relative;

      &::after {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
