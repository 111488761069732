@use '../../abstracts' as *;

/**
* @description UI - Promo popup (with disclaimer text)
*
*/

.tooltip-promo {
  position: relative;

  &__button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 0;
    cursor: pointer;
    gap: 0 8px;

    &-tag,
    &-icon {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &-tag {
      padding: 2px 6px;
      border: 1px solid currentcolor;
      border-radius: 4px;
      color: $color-red;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;

      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }

    &-text {
      margin: auto 0;
      color: $color-grey-paragraph;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    &-icon {
      color: $color-grey-paragraph;
      font-size: 16px;
    }

    &:focus,
    &:hover {
      .tooltip-promo__button {
        &-text,
        &-icon {
          color: $color-red;
        }
      }
    }
  }

  &__disclaimer {
    position: absolute;
    right: 15px;
    bottom: 45px;
    left: 15px;
    padding: 35px 15px 15px;
    border: 1px solid $color-disclaimer-text;
    border-radius: 4px;
    background-color: $color-light-grey-hover;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, -10px, 0);

    // TODO: Move into transitions
    transition: opacity 250ms $ease-in-out-cubic, transform 250ms $ease-in-out-cubic;
    will-change: opacity, transform;

    &::before,
    &::after {
      position: absolute;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }

    &::before {
      bottom: -10px;
      border-width: 10px 12.5px 0;
      border-color: $color-disclaimer-text transparent transparent transparent;
      margin-left: -12.5px;
    }

    &::after {
      bottom: -8px;
      border-width: 8px 11.5px 0;
      border-color: $color-light-grey-hover transparent transparent transparent;
      margin-left: -11.5px;
    }

    &-btn-close {
      position: absolute;
      top: 8px;
      right: 10px;
      display: flex;
      align-items: center;
      padding: 0;
      border: 0;
      background-color: transparent;

      .icon {
        padding-left: 1px;
        font-size: 20px;
      }
    }

    p {
      color: $color-dark-grey;
      font-size: 13px;
      line-height: 16px;
    }
  }

  input {
    display: none;

    &:checked + .tooltip-promo__disclaimer {
      opacity: 1;
      pointer-events: all;
      transform: translate3d(0, 0, 0);
    }
  }

  @include media-breakpoint-up(sm) {
    &__disclaimer {
      bottom: 50px;
    }
  }

  @include media-breakpoint-up(md) {
    &__disclaimer {
      bottom: 45px;
    }
  }
}
