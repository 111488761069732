// @use '../vendor/easings.net/easing' as *;

/**
* @description Abstract - Custom SCSS variables
*
*/

// === Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
) !default;

// === Taken from Toyota Style Guide
// xs (< 768 - mobile)
// sm (< 992 - tablet portrait)
// md (< 1200 - tablet landscape, small desktop)
// lg (>= 1200 - large desktop)
// xl (>= 1600 - very large desktop) xl was added only for exceptional purposes.,,

// === Colours
$color-black: #282830;
$color-transparent-black: rgb(40 40 48 / 90%);
$color-red: #f02;
$color-red-hover: #d40605;
$color-white: #fff;
$color-white-transparent: rgb(255 255 255 / 50%);
$color-white-transparent-light: rgb(255 255 255 / 10%);
$color-grey: #e4e4e4;
$color-light-grey: #f0f0f0;
$color-light-grey-hover: #e4e4e4;
$color-transparent-grey: gba(40, 40, 48, 0.05);
$color-grey-medium: #adadb0;
$color-grey-paragraph: #282828;
$color-dark-grey: #282830;
$color-transparent-light-grey: rgb(240 240 240 / 96%);
$color-transparent-medium-grey: rgb(108 112 115 / 65%);
$color-deep-purple: #673ab7;
$color-indigo: #3f51b5;
$color-blue: #2196f3;
$color-cyan: #00bcd4;
$color-teal: #009688;
$color-green: #4caf50;
$color-outline: #0072f0;

// Additional colours
$color-grey-medium-1: #6a6b70;
$color-nl-number-plate-yellow: #efc60a;
$color-nl-number-plate-blue: #0072f0;
$color-disclaimer-text: #d2d2d3;
$color-pagination-link: #333;
$color-pagination-border: #ddd;
$color-pagination-disabled: #aaa;
$color-gallery-background: rgb(0 0 0 / 70%);
$color-border-light-grey: $color-light-grey-hover;

// === Spacing
$block-padding-minor-xs: 30px;
$block-padding-minor-sm: 50px;
$block-padding-minor-md: 50px;
$block-padding-major-xs: 50px;
$block-padding-major-sm: 100px;
$block-padding-major-md: 100px;
$item-spacing-xs: 15px;
$item-spacing-sm: 30px;

//
$bootstrap-column-width: 30px;

// Taken from https://easings.net
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// ===
$mobile-dealer-map-list-item-height: 184px;
$image-hover-focus-transition: transform 250ms $ease-out-quad;
$image-hover-focus-scale: scale3d(1.05, 1.05, 1.05);

// === CSS variables
:root {
  --safe-area-inset-left: env(safe-area-inset-left, 0);
  --safe-area-inset-right: env(safe-area-inset-right, 0);
}
