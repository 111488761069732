@use '../../abstracts' as *;

/**
* @description UI - Table
*
*/

.table {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    color: $color-dark-grey;
    font-weight: bold;
  }

  td {
    color: $color-grey-paragraph;
  }

  td,
  th {
    background: $color-white;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    word-break: break-word;
  }

  &.is-responsive {
    margin-bottom: 50px;

    td,
    th {
      padding: 12px 15px;
      border-top: 1px solid $color-border-light-grey;
      border-right: 1px solid $color-border-light-grey;
    }

    @include media-breakpoint-only(xs) {
      margin-bottom: 30px;

      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        padding-bottom: 15px;
        border-top: 1px solid $color-border-light-grey;

        &:last-of-type {
          padding-bottom: 0;
        }
      }

      td {
        display: flex;
        flex-direction: row;
        padding: 0;
        border: none;
        border-right: 1px solid $color-border-light-grey;
        border-bottom: 1px solid $color-border-light-grey;
        border-left: 1px solid $color-border-light-grey;

        &::before,
        span {
          height: 100%;
          padding: 12px 15px;
        }

        &::before {
          display: inline-flex;
          width: 45%;
          height: inherit;
          flex-grow: 0;
          flex-shrink: 0;
          align-items: flex-start;
          border-right: 1px solid $color-border-light-grey;
          content: attr(data-title);
          font-weight: bold;
        }

        span {
          width: 55%;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      table {
        border-bottom: 1px solid $color-border-light-grey;
        border-left: 1px solid $color-border-light-grey;
      }
    }
  }

  &.is-standard {
    padding-bottom: 15px;

    > table {
      border: 1px solid $color-border-light-grey;
    }

    th,
    td {
      padding: 15px;
      border: 0;
    }

    th {
      border-bottom: 1px solid $color-border-light-grey;
    }
  }

  &.is-simple {
    th,
    td {
      padding: 0;
    }
  }
}
