@use '../../abstracts' as *;

/**
* @description UI - Gallery group
*
*/

.gallery-group {
  overflow: hidden;
  width: 100vw;
  margin: auto;

  &__container {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 300ms $ease-in-out-cubic;
    will-change: tranform;
  }

  .gallery {
    width: 100vw;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
