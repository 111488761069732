@use '../abstracts' as *;

.icon-svg {
  display: flex;
}

.icon-car {
  --size: 16px;
  --icon: var(--icon-car);
  --color: currentcolor;

  @include icon;
}

.icon-consumptions {
  --size: 16px;
  --icon: var(--icon-consumptions);
  --color: currentcolor;

  @include icon;
}

.icon-map-marker {
  --size: 16px;
  --icon: var(--icon-map-marker);
  --color: currentcolor;

  @include icon;
}

.icon-menu {
  --size: 32px;
  --icon: var(--icon-menu);
  --color: currentcolor;

  @include icon;
}

.icon-remove {
  --size: 24px;
  --icon: var(--icon-remove);
  --color: currentcolor;

  @include icon;
}

.icon-test-drive {
  --size: 24px;
  --icon: var(--icon-test-drive);
  --color: currentcolor;

  @include icon;
}

.icon-service.icon-service-and-maintenance,
.icon-service-and-maintenance {
  --size: 24px;
  --icon: var(--icon-service);
  --color: currentcolor;

  @include icon;
}

.icon-chevron-down {
  --size: 32px;
  --icon: var(--icon-chevron-down);
  --color: currentcolor;

  @include icon;
}

.icon-chevron-up {
  --size: 32px;
  --icon: var(--icon-chevron-up);
  --color: currentcolor;

  @include icon;
}

.icon-angle-right,
.icon-angle-left,
.icon-chevron-right {
  --size: 32px;
  --icon: var(--icon-chevron-right);
  --color: currentcolor;

  @include icon;
}

.icon-angle-left {
  rotate: 180deg;
}

.icon-external-link {
  --size: 24px;
  --icon: var(--icon-external-link);
  --color: currentcolor;

  @include icon;
}

.icon-phone {
  --size: 24px;
  --icon: var(--icon-phone);
  --color: currentcolor;

  @include icon;
}

.icon-whatsapp {
  --size: 24px;
  --icon: var(--icon-whatsapp);
  --color: currentcolor;

  @include icon;
}

.icon-info-sign {
  --size: 20px;
  --icon: var(--icon-info-sign);
  --color: currentcolor;

  @include icon;
}

.icon-file-alt {
  --size: 24px;
  --icon: var(--icon-file-alt);
  --color: currentcolor;

  @include icon;
}

.icon-download {
  --size: 24px;
  --icon: var(--icon-download);
  --color: currentcolor;

  @include icon;
}

.icon-email {
  --size: 24px;
  --icon: var(--icon-email);
  --color: currentcolor;

  @include icon;
}

.icon-time {
  --size: 20px;
  --icon: var(--icon-time);
  --color: currentcolor;

  @include icon;
}

.icon-key {
  --size: 20px;
  --icon: var(--icon-key);
  --color: currentcolor;

  @include icon;
}

.icon-calculator {
  --size: 20px;
  --icon: var(--icon-calculator);
  --color: currentcolor;

  @include icon;
}
