@use '../../abstracts' as *;

/**
* @description Block - Table
*
*/

.block-table {
  padding: $block-padding-major-xs 0 $block-padding-major-xs - 35px;

  @include media-breakpoint-up(sm) {
    padding-bottom: $block-padding-major-xs;
  }
}
