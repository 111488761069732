@use '../../abstracts' as *;

/**
* @description UI - Top tasks bar
*
*/

.top-tasks-bar {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 0;
  background-color: $color-white;

  &__body {
    display: flex;
  }

  &__item {
    display: flex;
    width: 50%;
    flex-direction: column;
    flex-grow: 0.5;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  &__link {
    text-align: center;

    .icon {
      width: 40px;
      height: 40px;
      transition: color 250ms $ease-out-cubic;
    }

    &:focus,
    &:hover {
      text-decoration: none;

      .icon {
        color: mix($color-grey-paragraph, #fff, 50%);
      }

      .top-tasks-bar__link-text {
        text-decoration: underline;
      }
    }

    &-icon {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      margin-inline: auto;
    }

    &-text {
      margin: 0 auto;
      color: $color-dark-grey;
      font-weight: 400;
    }
  }

  @include media-breakpoint-only(xs) {
    &__body {
      flex-wrap: wrap;
    }

    &.has-3-items,
    &.has-4-items {
      .top-tasks-bar {
        &__item {
          height: auto;
          justify-content: flex-start;
        }

        &__link {
          width: 100%;
          align-items: center;
          padding: 15px * 0.5 15px 5px;
          font-size: 13px;
          line-height: 18px;

          &-text {
            flex-grow: 1;
            flex-shrink: 1;
            hyphens: auto;
            text-align: center;
            word-break: break-word;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(sm) {
    &__item {
      padding: 0 35px;
    }
  }

  @include media-breakpoint-up(sm) {
    &__body {
      height: inherit;
      align-items: center;
      justify-content: center;
    }

    &__item {
      width: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__link {
      display: block;
      font-size: 16px;
      line-height: 18px;
    }
  }

  @include media-breakpoint-up(md) {
    &__item {
      padding: 0 50px;
    }
  }
}
