@use '../abstracts' as *;

@media print {
  img {
    max-width: 100% !important;
    page-break-inside: avoid;
  }

  * {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline;
    }
  }

  a[href] {
    &::after {
      content: ' (' attr(href) ')';
    }
  }

  a[href^='#'] {
    &::after {
      content: '';
    }
  }

  h2 {
    orphans: 3;
    page-break-after: avoid;
    widows: 3;
  }

  p {
    orphans: 3;
    widows: 3;
  }
}
