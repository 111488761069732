@use '../../abstracts' as *;

/**
* @description Dealer map - Dealer list
*
*/

.dealer-list {
  width: 100%;
  height: 100%;

  .no-results-found {
    font-size: 14px;
  }

  &__container {
    &.has-last-item-border {
      > :last-child {
        border-bottom: 1px solid $color-border-light-grey;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    display: block;
    overflow: hidden;

    &__body {
      overflow: hidden;
      height: auto;
      max-height: $mobile-dealer-map-list-item-height * 4;

      &.show-more {
        max-height: 3000px;
      }
    }

    &__footer {
      padding: 30px 15px;
      border-top: 1px solid $color-border-light-grey;
      text-align: center;

      &:empty {
        display: none;
      }
    }

    .no-results-found {
      padding: 0 15px 35px;
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;

    &__search {
      flex-grow: 0;
      flex-shrink: 0;
      border-bottom: 1px solid $color-border-light-grey;
    }

    &__container {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &__search + &__container {
      height: calc(100% - 162px);
    }

    &__footer {
      display: none;
    }

    .no-results-found {
      padding: 30px 20px;
    }
  }
}
