@use '../../abstracts' as *;

/**
* @description Dealer map - Dealer loader
*
*/

.dealer-loader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-only(xs) {
    height: 100vh;
    min-height: 320px;
  }

  @include media-breakpoint-up(sm) {
    height: 100%;
  }
}
