@use '../abstracts' as *;

/**
* @description Page - Contact
*
*/

.contact-page {
  // Add border to dealer map
  .dealer-map {
    border-bottom: 1px solid $color-border-light-grey;
  }

  // Remove top padding from first element and bottom padding/margin from bottom
  .dealer-map-script + .container {
    > :first-child {
      padding-top: 0;
    }

    > :last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &__title {
    padding-bottom: $block-padding-major-xs - 5px;
    text-align: center;

    h2 {
      padding-bottom: 15px;
    }
  }

  &__tables {
    border-bottom: 1px solid $color-border-light-grey;
  }

  // Add dividing line based upon sections
  .block,
  .row,
  .block-faq,
  .block-form {
    + .block-faq,
    + .block-form {
      @include top-light-grey-border;

      &::before {
        top: ($block-padding-major-xs - 5px) * -1;
      }

      @include media-breakpoint-up(sm) {
        &::before {
          top: ($block-padding-major-sm - 5px) * -1;
        }
      }
    }
  }

  // Smaller contact page - adjust
  @include media-breakpoint-only(xs) {
    .dealer-map-script + .container {
      padding-top: $block-padding-major-xs - 6px;
      padding-bottom: $block-padding-major-xs;

      > :last-child {
        &.block-table {
          .table {
            margin-bottom: 0;
          }
        }
      }
    }

    &__tables {
      .is-responsive,
      .is-standard {
        padding-bottom: 15px;
      }
    }

    :not(.table).block,
    .row {
      + .block-faq {
        margin-top: $block-padding-major-xs - 30px;
      }
    }

    :not(.table, .block-title).block,
    .row {
      + .block-form {
        margin-top: $block-padding-major-xs + 8px;
      }
    }

    // Exceptions for spacing
    .block-form + .block-faq {
      margin-top: $block-padding-major-xs;
    }

    .block-text + .block-table {
      padding-top: $block-padding-minor-sm;
    }

    .block-faq + .block-table {
      padding-top: 0;
    }

    &__tables + .block-title {
      margin-top: $block-padding-major-xs;
    }

    &.is-detail-page {
      .block-hero {
        padding-bottom: $block-padding-major-xs * 2;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .dealer-map-script + .container {
      padding-top: $block-padding-major-sm - 6px;
      padding-bottom: $block-padding-minor-sm;

      > :last-child {
        &.block-form {
          padding-bottom: $block-padding-minor-sm;
        }
      }
    }

    // Table padding
    &__tables {
      .is-responsive,
      .is-standard {
        padding-bottom: 30px;
      }
    }

    .block-form {
      padding-bottom: $block-padding-major-sm;
    }

    .block-table {
      padding-bottom: $block-padding-minor-sm;
    }

    // Table then faq
    .table + .block-faq {
      padding-top: $block-padding-minor-sm - 7px;
    }

    :not(.table, .block-title).block,
    .row {
      + .block-faq,
      + .block-form {
        margin-top: $block-padding-major-sm - 6px;
      }
    }

    .block-title {
      + .block-table,
      + .block-faq {
        padding: 0;
        margin-top: 0;
      }

      + .block-faq {
        margin-top: $block-padding-minor-sm - 7px;
      }

      + .block-table {
        margin-top: 0;
      }
    }

    .block-text {
      + .block-table {
        padding-top: 0;
      }

      + .block-table,
      + .block-faq {
        padding-top: $block-padding-minor-sm;
      }
    }

    .block-faq + .block-table {
      padding-top: 0;
    }

    .block-table + .block-form {
      margin-top: -6px;
    }

    &__tables + .block-title {
      margin-top: $block-padding-major-sm;
    }

    //
    &.is-detail-page {
      .dealer-map {
        height: 560px;
      }
    }
  }
}
