@use '../../abstracts' as *;

.slick-slider {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.slick-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-thumb {
  height: 160px;
}

.slick-initialized {
  .slick-slide {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.slick-track {
  height: 100vh;
}

.slick-slide {
  > div {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.gallery-toolbar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.category-group {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .list {
    margin: 0;
  }
}

.thumbnail-group {
  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
    width: 100%;
    height: 120px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;

    &__container {
      display: flex;
      height: 105px;
      align-items: flex-end;
      white-space: nowrap;
    }
  }
}

.thumbnail-item {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 2.5px;
  transition: height 250ms $ease-in-out-cubic;
  will-change: height;

  &::after {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: $color-dark-grey;
    content: '';
    inset: 0;
    opacity: 0.7;
    pointer-events: none;
  }

  img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.is-active-image {
    height: 120px;
  }

  &.is-active-gallery {
    &::after {
      opacity: 0;
    }
  }

  &.is-first-in-category {
    margin-left: 27.5px;
  }
}
