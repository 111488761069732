@use '../../abstracts' as *;

/**
* @description Block - Image
*
*/

.block-image {
  width: 100%;

  img {
    width: 100%;
  }

  &.is-resized {
    img {
      object-fit: cover;
    }

    @include media-breakpoint-only(xs) {
      img {
        height: 194px;
      }
    }

    @include media-breakpoint-only(sm) {
      img {
        height: 345px;
      }
    }

    @include media-breakpoint-up(md) {
      img {
        height: 380px;

        .col-md-3 & {
          height: 262px;
        }

        .col-md-4 & {
          height: 270px;
        }
      }
    }
  }
}
