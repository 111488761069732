@use '../../abstracts' as *;

/**
* @description Item - Review summary
*
*/

.item-review-summary {
  text-align: center;

  &__title {
    padding-bottom: 20px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
  }

  .review-summary {
    padding-top: 7px;
    text-align: left;
  }
}
