@use '../../abstracts' as *;

/**
* @description Transition - List
*
*/

.list {
  &.is-downloads {
    i,
    span {
      transition: color 250ms $ease-in-out-cubic;
    }
  }

  &.is-subnav {
    a {
      transition: color 250ms $ease-in-out-cubic;

      &::after {
        transform: translate3d(0, 100%, 0);
        transition: transform 250ms $ease-in-out-cubic;
      }

      &:focus,
      &:hover {
        color: $color-red;
        text-decoration: none;

        &::after {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  /*
    &.is-categorynav {
        a {
            transition: color 250ms $ease-in-out-cubic;

            &::after {
                transition: background-color 250ms $ease-in-out-cubic;
            }

            &:hover,
            &:active {
                text-decoration: none;
                color: $color-red;

                &::after {
                    background-color: $color-red;
                }
            }
        }
    }
    */
}
