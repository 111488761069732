@use '../../abstracts' as *;

/**
* @description UI - Loader
*
*/

.loader {
  position: relative;
  animation: loader-animation 1.1s infinite linear;
  text-indent: -9999em;
  transform: translateZ(0);

  &,
  &::after {
    width: 10em;
    height: 10em;
    border-radius: 50%;
  }

  &.is-big {
    margin: 60px 0;
    font-size: 10px;
  }

  &.is-small {
    font-size: 1.5px;
  }

  &.is-red {
    border-top: 1.1em solid rgba($color-red, 0.2);
    border-right: 1.1em solid rgba($color-red, 0.2);
    border-bottom: 1.1em solid rgba($color-red, 0.2);
    border-left: 1.1em solid $color-red;
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
