@use '../abstracts' as *;

.footer {
  width: 100%;
  height: auto;
  padding: 80px var(--safe-area-inset-left) 24px var(--safe-area-inset-left);

  &::before {
    display: block;
    width: 30vw;
    height: 0.3rem;
    background-color: $color-red-hover;
    content: '';
  }

  > .container {
    margin-block-start: 40px;
  }

  .footer-legal-content {
    padding-bottom: 30px;

    a {
      transition: all 200ms $ease-out-sine;

      &:focus:not(:hover) {
        box-shadow: inset 0 -1px $color-white, inset 0 -2px $color-red-hover;
        color: $color-red-hover;
      }

      &:hover:not(:focus) {
        box-shadow: inset 0 -1px $color-white, inset 0 -2px $color-dark-grey;
        text-decoration: none;
      }
    }
  }

  .footer-review {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    margin: auto auto 0 0;
    text-align: left;

    a,
    h5,
    p {
      font-size: 1.6rem;
      line-height: 24px;
    }

    h5 {
      padding-bottom: 3px;
      font-weight: normal;
    }

    p {
      color: $color-grey-paragraph;
    }

    a {
      padding-right: 0;
      color: $color-dark-grey;
      font-size: 14px;
      line-height: 26px;
      text-decoration: underline;
      transition: color 200ms $ease-out-sine;

      &:focus,
      &:hover {
        color: $color-red-hover;
      }
    }

    li {
      width: 100%;
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.is-centered {
      text-align: center;
    }
  }

  .footer-copyright-social {
    margin-top: 10px;
    text-align: center;

    &__copyright {
      width: 100%;
      padding-top: 24px;
      border-top: 1px solid $color-light-grey;
      margin-top: 32px;
      color: $color-grey-paragraph;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  @include media-breakpoint-only(xs) {
    .footer-review {
      padding-top: 0;
      padding-bottom: 32px;
    }
  }

  @include media-breakpoint-up(sm) {
    .footer-legal-content {
      padding-bottom: 10px;

      li {
        display: inline-block;
        width: auto;
        margin: 0 36px 15px 0;
      }
    }

    .footer-copyright-social {
      display: flex;

      .col-xs-12 {
        display: flex;
        align-items: center;
      }

      &__copyright {
        text-align: left;
      }
    }
  }

  a {
    padding-right: 25px;
    color: $color-black;
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    padding-top: 0;
  }

  h5 {
    margin-right: 6px;
  }

  .toyota-logo {
    margin-block-end: 32px;
    margin-block-start: 0;
  }
}

@include media-breakpoint-up(sm) {
  .footer {
    a {
      padding: 0;
    }

    .footer-legal-content {
      padding-bottom: 24px;
    }
  }
}
