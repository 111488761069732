@use '../../abstracts' as *;

/**
* @description Transition - Accordion
*
*/

.accordion {
  transition: max-height 350ms $ease-in-out-cubic;
  will-change: max-height;
}
