@use '../../abstracts' as *;

.review-stars {
  display: inline-flex;
  align-items: center;
  gap: 10px;

  &__item,
  &__amount {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__item {
    &,
    &-value {
      --size: 24px;

      position: relative;
      width: calc(var(--size) * 5);
      height: var(--size);

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(var(--size) * 5);
        height: var(--size);
        background-color: $color-grey-medium;
        content: '';
        mask-image: var(--icon-rating);
        mask-repeat: repeat-x;
        mask-size: var(--size);
      }
    }

    &-value {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      height: 100%;

      &::before {
        background-color: var(--color, $color-dark-grey);
      }
    }
  }

  &__amount {
    color: var(--color, $color-dark-grey);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }

  &.is-small {
    .review-stars {
      &__item {
        &,
        &-value {
          --size: 18px;
        }
      }
    }
  }
}
