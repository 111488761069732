@use '../../abstracts' as *;

/*    cookie-bar */
.cookie-bar-wrapper {
  z-index: 9998;

  .container {
    width: auto;
    padding: 0;
    text-align: center;
  }

  h5 {
    padding-bottom: 20px;
    color: $color-dark-grey;
    text-align: left;
  }

  p {
    padding-bottom: 24px;
    color: $color-grey-paragraph;
    text-align: left;

    a {
      color: $color-dark-grey;
      text-decoration: underline;
    }
  }

  button {
    margin: 0 auto;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 15px;
    background-color: $color-white;
    box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 20%);

    .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  @include media-breakpoint-only(sm) {
    padding: 0 25px;

    .container {
      width: auto;
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      width: 750px;
      padding: 30px;
      border-radius: 3px;
      margin: 0;
      background-color: $color-white;
      box-shadow: 1px 4px 15px 4px rgb(30 30 30 / 20%);
    }
  }
}
