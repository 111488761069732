@use '../../abstracts' as *;

/**
* @description Dealer map - Google Map
*
*/

.google-map {
  display: block;

  &,
  &__component {
    width: 100%;
  }

  @include media-breakpoint-only(xs) {
    transition: height 250ms $ease-in-out-cubic 250ms;
    will-change: height;

    &,
    &__component {
      height: 200px;
    }

    &.is-minimized {
      height: 0;
      transition: height 250ms $ease-in-out-cubic 0s;
    }
  }

  @include media-breakpoint-up(sm) {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;

    &,
    &__component {
      height: 100%;
    }
  }

  @include media-breakpoint-only(sm) {
    left: 384px;
    width: calc(100% - 384px);
  }

  @include media-breakpoint-up(md) {
    left: 530px;
    width: calc(100% - 530px);
  }
}
