@use '../../abstracts' as *;

.car-model-overview {
  display: flex;
  min-height: 0 !important;
  flex-wrap: wrap;

  .car-range__item img {
    object-fit: contain;
  }

  // Do not show a bottom border on the last row
  .car-range__item {
    // 2 columns
    @include media-breakpoint-down(xs) {
      &:nth-child(2n + 1):nth-last-child(1),
      &:nth-child(2n + 1):nth-last-child(2) {
        &::after,
        & ~ .car-range__item::after {
          display: none;
        }
      }
    }

    // 3 columns
    @include media-breakpoint-between(sm, md) {
      &:nth-child(3n + 1):nth-last-child(1),
      &:nth-child(3n + 1):nth-last-child(2),
      &:nth-child(3n + 1):nth-last-child(3) {
        &::after,
        & ~ .car-range__item::after {
          display: none;
        }
      }
    }

    // 4 columns
    @include media-breakpoint-up(lg) {
      &:nth-child(4n + 1):nth-last-child(1),
      &:nth-child(4n + 1):nth-last-child(2),
      &:nth-child(4n + 1):nth-last-child(3),
      &:nth-child(4n + 1):nth-last-child(4) {
        &::after,
        & ~ .car-range__item::after {
          display: none;
        }
      }
    }
  }

  // Do not remove margin on last item
  .car-range__item:last-of-type {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}
