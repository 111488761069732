@use '../../abstracts' as *;

/**
* @description Overlay
*
*/

.overlay {
  &__background {
    pointer-events: none;

    &::before {
      opacity: 0;
      transition: opacity 500ms $ease-in-out-cubic 150ms;
      will-change: opacity;
    }

    &::after {
      transform: translate3d(0, 100%, 0);
      transition: transform 500ms $ease-in-out-cubic;
      will-change: transform;
    }

    &.show-background {
      &::before {
        opacity: 1;
      }

      &::after {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__body {
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms $ease-in-out-cubic;

    &.show-body {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__btn-close {
    pointer-events: none;
    transform: scale3d(0, 0, 0);
    transition: transform 250ms $ease-in-out-cubic;

    &.show-button {
      pointer-events: all;
      transform: scale3d(1, 1, 1);
    }
  }
}
