@font-face {
  font-display: swap;
  font-family: Toyota;
  font-style: normal;
  font-weight: 400;
  src: url('/static/toyota/fonts/ToyotaType-Book.woff2') format('woff2'),
    url('/static/toyota/fonts/ToyotaType-Book.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Toyota;
  font-style: normal;
  font-weight: 500;
  src: url('/static/toyota/fonts/ToyotaType-Regular.woff2') format('woff2'),
    url('/static/toyota/fonts/ToyotaType-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Toyota;
  font-style: normal;
  font-weight: 600;
  src: url('/static/toyota/fonts/ToyotaType-Semibold.woff2') format('woff2'),
    url('/static/toyota/fonts/ToyotaType-Semibold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Toyota;
  font-style: normal;
  font-weight: 700;
  src: url('/static/toyota/fonts/ToyotaType-Bold.woff2') format('woff2'),
    url('/static/toyota/fonts/ToyotaType-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Toyota;
  font-style: normal;
  font-weight: 800;
  src: url('/static/toyota/fonts/ToyotaType-Book.woff2') format('woff2'),
    url('/static/toyota/fonts/ToyotaType-Book.woff') format('woff');
}
