@use '../../abstracts' as *;

/**
* @description Page - Job overview
*
*/

.block-job-overview {
  .row {
    > .col-xs-12 {
      padding-bottom: 20px;
    }
  }

  &__pagination {
    width: 100%;
    padding-top: 15px;
    padding-bottom: $block-padding-major-xs;
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    .filter-list {
      margin-bottom: $block-padding-minor-sm;
    }

    .row {
      > .col-xs-12 {
        padding-bottom: 30px;
      }
    }

    &__pagination {
      padding-top: 20px;
      padding-bottom: $block-padding-major-sm;
    }
  }
}
