@use '../abstracts' as *;

/**
* @description Page - Error
*
*/

.error-page {
  .block-hero.is-default {
    padding: 0;

    .block-hero__image {
      padding-bottom: 0;
    }
  }

  .block-title {
    padding-top: $block-padding-major-xs - 6px;

    .intro {
      padding-top: $block-padding-minor-xs - 10px;
    }
  }

  @include media-breakpoint-up(sm) {
    .block-hero.is-default {
      padding-top: 0;

      .block-hero__image {
        padding-bottom: 0;
      }
    }

    .block-title {
      padding-top: $block-padding-major-sm - 6px;
      padding-bottom: $block-padding-major-sm - 6px;

      .intro {
        padding-top: $block-padding-minor-sm - 10px;
      }
    }
  }
}
