@use '../../abstracts' as *;

/**
* @description Overlay
*
*/

.overlay {
  position: fixed;
  z-index: 9999;
  inset: 0;

  &__background {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    inset: 0;

    &::before,
    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      inset: 0;
    }

    &::before {
      z-index: 1;
      background-color: $color-transparent-black;
    }

    &::after {
      z-index: 2;
      background-color: $color-white;
    }
  }

  &__body {
    position: absolute;
    z-index: 3;
    display: block;
    width: 100%;
    height: 100%;
    inset: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;

    &-iframe {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__btn-close {
    position: absolute;
    z-index: 4;
    top: 16px;
    right: 16px;
    display: flex;
    width: 33px;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 100%;
    aspect-ratio: 1;
    background-color: $color-white;

    &:active,
    &:hover {
      text-decoration: none;
    }

    i {
      --size: 20px;
    }
  }

  &__body-iframe.is-youtube {
    padding-top: 50px;
    background-color: black;
  }

  &.is-gallery {
    .overlay {
      &__background {
        &::before {
          background-color: $color-gallery-background;
        }

        &::after {
          display: none;
        }
      }

      &__btn-close {
        i {
          color: $color-white;
        }
      }
    }
  }

  &.is-iframe .overlay__body {
    overflow-y: hidden;
  }
}
