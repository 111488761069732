@use '../../abstracts' as *;

/**
* @description UI - Rich text
*
*/

.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5 {
    padding-bottom: 20px;
    color: $color-dark-grey;
  }

  p,
  ol,
  ul {
    padding-bottom: 15px;

    + h5 {
      padding-top: 20px;
    }
  }

  p {
    text-indent: 0;

    &:empty {
      display: none;
    }
  }

  i {
    font-style: italic;
  }

  ol,
  ul {
    line-height: 26px;

    li {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 25px;

      &::before {
        position: absolute;
        top: 11px;
        left: 0;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: $color-grey-paragraph;
        content: '';
      }
    }
  }

  ol {
    padding-left: 0;
    margin-left: 15px;

    li {
      padding-left: 10px;
    }
  }

  :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
