@use 'variables' as *;
@use 'mixins.breakpoints' as *;
@use 'sass:map';

// Mixin - Add grey border between section blocks
@mixin top-light-grey-border() {
  position: relative;

  &::before {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background-color: $color-border-light-grey;
    content: '';

    @include media-breakpoint-only(sm) {
      max-width: map.get($grid-breakpoints, sm) - 48px;
    }

    @include media-breakpoint-only(md) {
      max-width: map.get($grid-breakpoints, md) - 52px;
    }

    @include media-breakpoint-up(lg) {
      max-width: map.get($grid-breakpoints, lg) - 60px;
    }
  }
}
