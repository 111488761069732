@use '../../abstracts' as *;

/**
* @description Block - Team
*
*/

.block-team {
  &__footer {
    text-align: center;

    .pagination {
      font-size: 0;
    }
  }

  @include media-breakpoint-only(xs) {
    padding-bottom: $block-padding-major-xs;

    .team-item {
      margin: 0 auto 15px;
    }

    .col-xs-12:last-of-type {
      .team-item {
        margin-bottom: 0;
      }
    }

    &__footer {
      padding-top: $block-padding-minor-xs;
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: $block-padding-major-sm - 30px;

    .team-item {
      margin-bottom: 30px;
    }

    &__footer {
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }
}
