@use '../../abstracts' as *;

/**
* @description Item - Team
*
*/

.team-item {
  position: relative;
  overflow: hidden;
  border: 1px solid $color-border-light-grey;

  &__photo {
    position: relative;
    overflow: hidden;
    padding-bottom: 133%;
    background-color: $color-light-grey;

    img {
      position: absolute;
      width: 100%;
      transform: scale(1);

      // TODO: Move to transitions
      transition: transform 300ms $ease-in-out-cubic;
      will-change: transform;
    }
  }

  &__body {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;

    &-title {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 85px 23px 30px;

      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 145px;
        background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
        content: '';
        opacity: 0.5;
      }

      h5 {
        position: relative;
        color: $color-white;
      }

      i {
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }

    &-toggle {
      position: absolute;
      z-index: 2;
      right: 30px;
      bottom: 30px;
      display: block;
      width: 44px;
      height: 43px;
      border: 1px solid $color-white;
      border-radius: 100%;

      svg {
        width: 100%;
        height: 100%;
        fill: $color-white;
        transition: transform 300ms $ease-in-out-cubic;
      }
    }

    &-info {
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 30px;
      background-color: rgba($color-red, 0.85);
      inset: 0;
      opacity: 0;
      pointer-events: none;

      // TODO: Move to transitions
      transition: opacity 300ms $ease-in-out-cubic;
      will-change: opacity;
    }

    &-link {
      display: inline-flex;
      align-items: center;
      color: $color-white;
      font-size: 16px;
      line-height: 26px;

      &.is-email {
        font-size: 13px;
        line-height: 21px;
      }

      i {
        padding-right: 7px;
      }
    }
  }

  &:focus,
  &:hover {
    cursor: pointer;

    .team-item {
      &__photo {
        img {
          transform: scale(1.1);
        }
      }

      &__body {
        &-toggle {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &.is-open {
    .team-item {
      &__body {
        &-info {
          opacity: 1;
        }

        &-toggle {
          svg {
            transform: rotate(135deg);
          }
        }
      }
    }
  }

  @include media-breakpoint-only-xxxs {
    &__body {
      &-title {
        bottom: 12px;
        left: 19px;
      }

      &-toggle {
        right: 20px;
        bottom: 20px;
      }

      &-info {
        padding: 20px;
      }

      &-link {
        &.is-email {
          font-size: 13px;
          line-height: 21px;
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    max-width: 343px;
  }
}
