@use '../../abstracts' as *;

/**
* @description Dealer map - Dealer search
*
*/
.dealer-search {
  padding: 20px 15px;

  &__title {
    display: flex;
    padding-bottom: 17px;

    h6 {
      margin: auto auto auto 0;
    }

    span {
      margin: auto 0 auto auto;
      color: $color-grey-paragraph;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__input {
    position: relative;

    input {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 46px;
      padding: 15px;
      border: 0;
      border-radius: 4px;
      background-color: $color-light-grey;
    }

    button {
      position: absolute;
      z-index: 2;
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;

      // todo icon ?
    }
  }

  &__btn-clear {
    top: 13px;
    right: 44px;
    padding: 0;
    border-radius: 100%;
    background-color: $color-grey-medium;
    color: $color-white;
    font-size: 16px;
    line-height: 16px;
    pointer-events: none;
    transform: scale3d(0, 0, 0);
    transition: transform 300ms $ease-out-cubic;
    will-change: transform;

    &.show-btn {
      pointer-events: all;
      transform: scale3d(1, 1, 1);
      transition: transform 300ms $ease-in-out-cubic;
    }
  }

  &__btn-submit {
    top: 12px;
    right: 15px;
    background-color: transparent;
    color: $color-dark-grey;
    font-size: 20px;
    line-height: 20px;
  }

  &__footer {
    padding-top: 17px;

    &-indicator {
      display: flex;
      height: 21px;
      align-items: center;
      padding: 0;
      border: 0;
      background-color: transparent;
      color: $color-dark-grey;
      font-size: 13px;
      line-height: 16px;

      .icon {
        font-size: 16px;
        line-height: 18px;
      }

      i,
      svg {
        position: absolute;
        left: 0;
      }

      svg {
        top: 2px;
        width: 16px;
        fill: $color-green;
      }

      .is-red {
        color: $color-red;
      }

      &-icon {
        position: relative;
        display: inline-block;
        width: 24px;
        height: 18px;
        flex-grow: 0;
        flex-shrink: 0;

        .icon {
          top: 0;
        }

        .loader {
          top: 2px;
        }

        .glyphicon-ban-circle {
          top: 2px;
        }
      }
    }
  }
}
