@use '../abstracts' as *;

/**
* @description UI Kit - Spotlight override
*
* @note The old version used bootstrap but we're using flexbox in this instance
*/

.modal {
  background-color: rgb(0 0 0 / 40%);

  &.show {
    z-index: 1060;
    display: flex !important;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.modal-content {
  background-color: $color-white;
}

.modal-dialog {
  margin: 0 auto auto;

  @include media-breakpoint-only(xs) {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    border-radius: 3px;
    margin: auto;
  }
}

.modal-header {
  display: flex;
  min-height: 0;
  padding: 14px 20px 13px;
  border-bottom: 0;

  .close {
    display: none;
  }

  //
  @include media-breakpoint-up(sm) {
    padding: 23px 30px;

    .close {
      display: block;
      width: 30px;
      height: 30px;
      padding: 0;
      border: 0;
      margin: -4px -9px 0 0;
      background-color: transparent;
      color: $color-grey-medium-1;
      font-size: 30px;
      line-height: 0;
    }
  }
}

.modal-title {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  color: $color-dark-grey;
}

.modal-body {
  padding: 0 15px 5px;

  @include media-breakpoint-up(sm) {
    padding: 0 30px 20px;
  }
}

.modal-footer {
  border-top: 0;

  .btn {
    margin-right: 15px;
    margin-left: 0;

    &:last-of-type {
      margin-right: 0;
      margin-left: 0;
    }
  }

  @include media-breakpoint-only(xs) {
    padding: 15px 15px 20px;

    .btn {
      min-width: 0;
      padding-right: 28px;
      padding-left: 27px;
    }
  }

  @include media-breakpoint-only-xxxs {
    display: block;
    padding: 15px 15px 20px;

    .btn {
      width: 100%;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 0 30px 30px;
  }
}

body {
  overflow: hidden;
}
