@use '../../abstracts' as *;

/**
* @description Item - Used Car
*
*/

.item-used-car {
  display: block;
  width: 100%;
  border: 1px solid $color-grey;
  background-color: $color-white;

  &__image {
    width: 100%;

    img {
      height: auto;
    }
  }

  &__body {
    padding: 15px;
  }

  &__link {
    &::before {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      cursor: pointer;
      inset: 0;
    }
  }

  &__title {
    padding-bottom: 9px;
    color: $color-dark-grey;
    font-size: 18px;
    font-weight: 500;
    font-weight: normal;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }

  &__description {
    padding-bottom: 19px;
    color: $color-grey-paragraph;
    font-size: 14px;
    line-height: 16px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 0;

    li {
      display: flex;
      width: 50%;
      align-items: center;
      gap: 0.75rem;
    }

    span {
      position: relative;
      top: -1px;
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__footer {
    display: flex;
    max-height: 25px;
    gap: 12px;

    li {
      width: 50%;

      > h4 {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  &__price {
    color: $color-grey-paragraph;
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;

    &--lease {
      font-size: 75%;
    }
  }

  &__guarantee {
    height: 100%;
    text-align: right;

    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right center;
    }
  }

  @include media-breakpoint-only(xs) {
    margin-bottom: 15px;
  }

  @include media-breakpoint-only(sm) {
    &__price {
      position: relative;
      top: 1px;
      font-size: 18px;
      line-height: 24px;
    }

    &__guarantee {
      padding-bottom: 1px;
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    height: 100%;
    flex-direction: column;

    &__image {
      flex-grow: 0;
      flex-shrink: 0;

      img {
        object-fit: cover;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &__footer {
      margin: auto 0 0;
    }
  }

  @include media-breakpoint-up(lg) {
    &__price {
      position: relative;
      top: 1px;
      font-size: 18px;
      line-height: 24px;
    }

    &__guarantee {
      padding-bottom: 1px;
    }
  }
}
