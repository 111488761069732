@use '../../abstracts' as *;

/**
* @description Item - News promo
*
*/

.item-newspromo {
  $self: &;

  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: $item-spacing-xs;

  &:is(:hover) {
    #{$self}__body {
      border-color: #15151b !important;
      background-color: #15151b;

      * {
        color: $color-white;
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: hsl(206deg 3% 44% / 30%);

    &.is-scale-to-fit {
      img {
        width: 100%;
        height: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        object-fit: cover;
      }
    }

    &.is-placeholder {
      display: flex;

      img {
        margin: auto;
      }
    }
  }

  &__link {
    display: block;
    font-size: 20px;
    line-height: 24px;

    span {
      position: absolute;
      z-index: 1;
      display: block;
      width: 100%;
      background-color: rgb(0 0 0 / 0.1%);
      content: '';
      cursor: pointer;
      inset: 0 0 15px;
    }

    &:focus,
    &:hover {
      text-decoration: none;

      .item-newspromo__body-title {
        text-decoration: underline;
      }
    }

    > h3 {
      margin: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 20px;
    background-color: $color-white;
    gap: 16px;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    > .justify-end {
      margin-block-start: auto;
    }

    .tooltip-promo__button {
      padding: 0;
    }

    * {
      transition: color 0.2s ease-in-out;
    }

    .tooltip-promo {
      z-index: 2;
    }

    &-date {
      display: block;
      color: $color-grey-paragraph;
      font-size: 13px;
      line-height: 16px;
    }

    &-title {
      color: $color-dark-grey;
      font-size: 19px;
      font-weight: bold;
      line-height: 21px;

      &.is-red {
        color: $color-red;
      }
    }

    &-text {
      color: $color-grey-paragraph;
      font-size: 16px;
      line-height: 26px;
    }
  }

  //
  .bg-light-grey & {
    .item-newspromo__body {
      background-color: $color-white;
    }
  }

  .bg-white & {
    .item-newspromo__body {
      border-block-end: 1px solid $color-border-light-grey;
      border-inline: 1px solid $color-border-light-grey;
    }

    .item-newspromo__image {
      border-block-start: 1px solid $color-border-light-grey;
      border-inline: 1px solid $color-border-light-grey;
    }
  }

  @include media-breakpoint-only(xs) {
    &__image {
      &.is-placeholder {
        display: flex;

        img {
          height: 32vw;
          padding: $block-padding-minor-xs 0;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: $item-spacing-sm;

    &__body-date {
      display: flex;
      align-items: center;
    }

    &__link {
      span {
        bottom: 30px;
      }
    }
  }

  @include media-breakpoint-only(sm) {
    &__image {
      &.is-placeholder {
        img {
          height: 115px;
          padding: 20px 0;
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    &__image {
      // Big (default)
      &.is-placeholder {
        img {
          height: 152px;
          padding: 20px 0;
        }
      }

      // Small
      .col-md-4 & {
        &.is-placeholder {
          img {
            height: 98px;
            padding: 15px 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__image {
      // Big (default)
      &.is-placeholder {
        img {
          height: 185px;
          padding: 30px 0;
        }
      }

      // Small
      .col-md-4 & {
        &.is-placeholder {
          img {
            height: 125px;
            padding: 20px 0;
          }
        }
      }
    }
  }
}
