@mixin icon-before {
  &::before {
    position: absolute;
    top: var(--icon-top, 0);
    left: var(--icon-left, 0);
    width: var(--size, 1.5rem);
    height: var(--size, 1.5rem);
    background-color: var(--color, black);
    content: '';
    mask-image: var(--icon);
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: var(--size, 1.5rem) var(--size, 1.5rem);
    transition: background-color 0.15s ease, left 0.15s ease, top 0.15s ease, width 0.15s ease,
      height 0.15s ease, mask-size 0.15s ease;
  }
}

@mixin icon {
  display: inline-flex;
  width: var(--size, 1.5rem);
  height: var(--size, 1.5rem);
  background-color: var(--color, black);
  mask-image: var(--icon);
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: var(--size, 1.5rem);
}
