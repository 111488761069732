@use '../../abstracts' as *;

.header {
  z-index: 1030;
  top: 0;
  width: 100%;

  @include media-breakpoint-up(lg) {
    position: fixed;
  }
}
