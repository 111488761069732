@use '../../abstracts' as *;

/**
* @description Block - Car model
*
*/

.block-car-model {
  &__image {
    display: block;
    width: 100%;
    height: 224px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
    }
  }

  &__separator {
    position: relative;

    @include media-breakpoint-up(sm) {
      &::after {
        position: absolute;
        width: 3px;
        background: $color-red;
        content: '';
        inset: 0 12px 0 auto;
      }
    }
  }

  &__title {
    padding-top: $block-padding-major-xs - 18px;
    padding-bottom: 17px;

    @include media-breakpoint-down(xs) {
      text-align: center;
    }

    &-text {
      padding: 0;
      margin-top: 0;
      margin-bottom: 1.6rem;
      font-size: 2.8rem;
      line-height: 3.2rem;
      text-align: left;
      text-transform: none;

      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }

    &-tagline {
      display: block;
      margin-top: 11px;
      color: $color-grey-paragraph;
      font-size: 13px;
      font-style: italic;
      font-weight: bold;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  &__introtext {
    > p {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  &__introtext .rich-text {
    padding-bottom: 18px;

    &,
    p {
      color: $color-black;
      font-weight: 500;

      a {
        text-decoration: underline;
      }
    }

    p {
      padding-bottom: 1em;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__price {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;

    &-from {
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &-amount {
      font-size: 24px;
      font-weight: bold;
      line-height: 27px;
    }

    &-disclaimer {
      font-size: 13px;
      line-height: 16px;
      text-decoration: underline;
    }
  }

  &__property {
    padding-top: 10px;
    color: $color-black;
    font-weight: 400;

    &-label {
      display: block;
      font-size: 13px;
      line-height: 20px;
    }

    &-amount {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__info {
    padding-top: 20px;
  }

  &__disclaimer {
    p {
      padding-top: $block-padding-minor-xs;
      color: $color-grey-paragraph;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__btn-group {
    justify-content: center;
    padding: 40px 0;

    @include media-breakpoint-up(md) {
      padding: 40px 0 80px;
    }

    // container queries not fully supported but it's a nice feature for browsers that supports it
    container-type: inline-size;

    > :not(:first-child.btn) {
      --color: 240 9% 17%;
      --background-color: transparent;
      --border-color: 240 9% 17%;

      border-block-end: 1px solid hsl(var(--border-color) / var(--opacity));
      padding-inline: 0;

      &:is(:disabled, .is-disabled) {
        --color: 0 0% 89%;
        --background-color: transparent;
        --border-color: 0 0% 89%;
      }

      &:hover {
        --opacity: 0.8;
      }

      &:active {
        --opacity: 1;
      }
    }

    > .btn {
      @media (width <= 599px) {
        flex-basis: 100%;
        justify-content: center;
        text-align: center;
      }

      @container (max-width: 24em) {
        flex-basis: 100%;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .is-hybrid {
    color: $color-blue;
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
    line-height: 16px;
  }

  .container {
    padding-bottom: $block-padding-major-xs - 5px;

    > :last-child {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-only(xs) {
    &__title {
      &-text {
        color: $color-dark-grey;
      }
    }

    &__info {
      text-align: center;
    }

    // Adjust
    &__introtext + &__info {
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    position: relative;

    &__title {
      position: absolute;
      top: 0;
      max-width: 35%;
    }

    &__introtext .rich-text {
      padding-top: $block-padding-minor-sm;

      &,
      p {
        font-size: 24px;
        line-height: 30px;
      }
    }

    // Adjust
    &__introtext + &__info {
      padding-top: 0;
    }

    &__image {
      height: 456px;
    }

    &.has-text-black {
      .block-car-model {
        &__title {
          &-text {
            color: $color-dark-grey;
          }

          &-tagline {
            color: $color-grey-paragraph;
          }
        }
      }
    }

    &.has-text-white {
      .block-car-model {
        &__title {
          &-text,
          &-tagline {
            color: $color-white;
            text-shadow: 0 1px 0 rgb(0 0 0 / 20%);
          }
        }

        &__introtext > p {
          color: $color-white;
          text-shadow: 0 1px 0 rgb(0 0 0 / 20%);
        }
      }
    }
  }

  @include media-breakpoint-only(sm) {
    &__title-text {
      &.has-short-title {
        font-size: 60px;
        line-height: 60px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__title-text {
      &.has-short-title {
        font-size: 80px;
        line-height: 80px;
      }
    }

    &__disclaimer {
      max-width: 75%;
    }
  }

  // We don't want our images to overscale
  @include media-breakpoint-up(xl) {
    &__title {
      padding-top: 3vw;
    }
  }
}
