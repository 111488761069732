@use '../../abstracts' as *;

.block-intro-text-spotlights {
  display: flex;
  flex-wrap: wrap;
  padding-right: var(--safe-area-inset-right);
  padding-left: var(--safe-area-inset-left);
  border-bottom: 1px solid $color-border-light-grey;

  &__item {
    width: 50%;
    margin-bottom: $block-padding-major-xs - 7px;
  }

  .spotlight-box {
    margin-bottom: 0;

    strong {
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }

    &:focus,
    &:hover {
      text-decoration: none;

      img {
        transform: scale3d(1.05, 1.05, 1.05);
      }

      strong {
        text-decoration: underline;
      }
    }

    &-image {
      position: relative;
      display: block;
      overflow: hidden;
      padding: 0 0 44.87%;
      margin: 0;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      object-fit: cover;
      transition: transform 250ms $ease-out-quad;
    }

    .container-description {
      padding: 13px 14px 0;
    }

    .description {
      color: $color-grey-paragraph;
      line-height: 26px;
    }
  }

  @include media-breakpoint-only-xxxs {
    &__item {
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;

    &__item {
      width: 25%;
      margin-bottom: $block-padding-major-sm - 8px;
    }

    .spotlight-box {
      .container-description {
        padding: 17px 19px 0;
      }
    }
  }
}
