@use '../abstracts' as *;

.shopping-tools {
  --border-color: #{$color-white-transparent-light};

  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: 3px;

  ul {
    margin: 0;

    > li {
      + li {
        border-top: 1px solid var(--border-color);
      }

      > a {
        display: flex !important;
        align-items: flex-start;
        padding: 19px 15px 20px;
        color: currentcolor;
        gap: 0 8px;
        transition: background-color 150ms $ease-in-sine 150ms;

        .icon {
          --size: 20px;

          flex-shrink: 0;
          margin-block-start: -2px;
        }

        .icon-label {
          flex: 1;
          font-size: 16px;
          line-height: 16px;
        }

        &:hover {
          background-color: hsl(206deg 3% 44% / 30%);
        }
      }
    }
  }
}

@media (width <= 767.9px) {
  .social-media-links {
    .social-media-item {
      a {
        border: 1px solid #fff;
        opacity: 1;
      }
    }
  }
}
