@use '../../abstracts' as *;

/**
* @description Block - Intro text
*
*/

.block-intro-text {
  p {
    color: $color-dark-grey;
    text-align: inherit;
  }
}
