@use '../../abstracts' as *;

/**
* @description Block - Car model reviews
*
*/

.block-car-model-reviews {
  padding-top: $block-padding-major-xs - 5px;
  padding-bottom: $block-padding-major-xs;

  &:empty {
    display: none;
  }

  &__title,
  h1 {
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  &__subtitle {
    display: block;
    height: 16px;
    padding-bottom: 30px;
    color: $color-grey-paragraph;
    line-height: 16px;
    text-align: center;

    span {
      color: $color-black;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  @include media-breakpoint-only(xs) {
    .item-review-summary {
      padding-top: 13px;
      padding-bottom: 13px;
      border-top: 1px solid $color-border-light-grey;
    }

    .item-review-detail {
      padding-top: 13px;
      padding-bottom: 16px;
      border-top: 1px solid $color-border-light-grey;
    }

    .col-md-3 {
      &:last-of-type {
        .item-review-detail {
          border-bottom: 1px solid $color-border-light-grey;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: $block-padding-major-sm - 7px;
    padding-bottom: $block-padding-minor-sm + 5px;

    &__subtitle {
      padding-bottom: $block-padding-minor-sm + 7px;
    }

    .col-xs-12:nth-child(2) {
      padding-top: $block-padding-minor-sm - 2px;
    }

    .item-review-detail,
    .item-review-summary {
      padding-bottom: $block-padding-minor-sm - 10px;
    }
  }

  @include media-breakpoint-up(sm) {
    .col-xs-12 {
      padding-top: $block-padding-minor-sm - 2px;

      &:nth-child(1) {
        padding-top: 0;
      }
    }
  }
}
