@use '../../abstracts' as *;

/**
* @description Block - News & promotions
*
*/

.block-newspromo {
  padding-top: $block-padding-major-xs - 5px;
  padding-bottom: $block-padding-major-xs;

  &__title {
    padding-bottom: $block-padding-minor-xs - 6px;
    color: $color-white;
    text-align: center;

    .bg-white & {
      color: $color-black;
    }
  }

  &__footer {
    width: 100%;
    margin-top: $block-padding-minor-xs - $item-spacing-xs;
    text-align: center;
  }

  @include media-breakpoint-only(xs) {
    .row {
      :last-child {
        .item-newspromo {
          padding-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: $block-padding-major-sm - 7px;
    padding-bottom: $block-padding-major-sm - $item-spacing-sm;

    &.is-related {
      padding-bottom: $block-padding-major-sm - $item-spacing-sm;
    }

    &__title {
      padding-bottom: $block-padding-minor-sm - 8px;
    }

    &__footer {
      margin-top: $block-padding-minor-sm - $item-spacing-sm;
      margin-bottom: $bootstrap-column-width;

      .btn {
        margin-top: 0;
      }
    }
  }
}
