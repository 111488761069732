@use '../../abstracts' as *;

/**
* @description Dealer map - Main application
*
*/

.dealer-map {
  position: relative;
  background-color: $color-white;
  border-block: 1px solid $color-border-light-grey;

  @include media-breakpoint-only(xs) {
    min-height: 200px;
  }

  @include media-breakpoint-up(sm) {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 650px;
  }

  @include media-breakpoint-up(md) {
    &.has-search-enabled {
      height: 816px;
    }
  }
}
