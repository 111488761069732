@use '../../abstracts' as *;

/**
* @description Item - Seller info
*
*/

.item-seller-info {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid $color-border-light-grey;
  margin-bottom: 15px;
  background-color: $color-white;

  &__intro {
    display: flex;
    min-height: 94px;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    padding: 15px;
    background-color: $color-grey-paragraph;

    h3 {
      flex-grow: 1;
      flex-shrink: 1;
      padding-right: 15px;
      margin: auto auto auto 0;
      margin: 0;
      color: $color-white;
      font-size: 13px;
      font-weight: normal;
      line-height: 26px;
    }

    span {
      display: block;
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
    }

    &-image {
      overflow: hidden;
      width: 51px;
      height: 67px;
      flex-grow: 0;
      flex-shrink: 0;
      border: 1px solid $color-border-light-grey;
      margin: 0 0 0 auto;
      background-color: $color-light-grey-hover;

      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }

  &__body {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 15px;

    h4 {
      padding-bottom: 10px;
    }

    p {
      padding-bottom: 5px;
      color: $color-grey-paragraph;
    }

    a {
      display: flex;
      align-items: center;
      color: $color-grey-paragraph;
      text-decoration: none;

      &:active,
      &:hover {
        text-decoration: underline;
      }
    }

    i {
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 9px;
      margin: auto 0;
      color: $color-dark-grey;
      font-size: 16px;
      line-height: 18px;
    }

    span {
      flex-grow: 1;
      flex-shrink: 1;
      margin: auto auto auto 0;
      font-size: 13px;
      line-height: 26px;
    }
  }

  @include media-breakpoint-up(sm) {
    height: 100%;
  }
}
