@use '../../abstracts' as *;

/**
* @description Page - Review overview
*
*/

.block-review-overview {
  &__footer {
    padding-top: $block-padding-minor-xs;
    padding-bottom: $block-padding-major-xs;
    text-align: center;
  }

  @include media-breakpoint-only(xs) {
    .item-review-detail {
      padding-top: 28px;
      padding-bottom: 12px;
      border-top: 1px solid $color-light-grey-hover;
    }
  }

  @include media-breakpoint-up(sm) {
    .item-review-detail {
      padding-bottom: 32px;
    }

    &__subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 22px;
      padding-bottom: 0;
      padding-left: 28px;
    }

    &__footer {
      padding-top: 0;
      padding-bottom: $block-padding-major-sm;
    }
  }
}
