@use '../abstracts' as *;

/**
* @description Page - Content
*
*/

.content-page {
  // Set small spacing
  .block + .block {
    padding-top: 0;
  }

  // Clear top padding on blocks that are the same
  .bg-white
    + .bg-white:not(
      .block-buttons,
      .block-faq,
      .block-taxation,
      .block-usps,
      .block-title,
      .block-intro-text
    ),
  .bg-light-grey + .bg-light-grey,
  .bg-grey-paragraph + .bg-grey-paragraph,
  .block-intro-text + .bg-white {
    padding-bottom: 0;
  }

  //
  // Block formatting adjustment
  .table.is-responsive {
    margin-bottom: 0;
  }

  // Add dividing line based upon sections
  .block,
  .block-download-links,
  .block-intro-text,
  .row {
    + .block-car-model-gallery,
    + .block-faq,
    + .block-form,
    + .block-taxation {
      @include top-light-grey-border;

      &::before {
        top: ($block-padding-major-xs - 5px) * -1;
      }

      @include media-breakpoint-up(sm) {
        &::before {
          top: ($block-padding-major-sm - 5px) * -1;
        }
      }
    }
  }

  .block-form,
  .block-job-overview,
  .block-team {
    + .row,
    + .block {
      @include top-light-grey-border;

      &::before {
        margin-bottom: $block-padding-major-xs;
      }

      @include media-breakpoint-up(sm) {
        &::before {
          margin-bottom: $block-padding-major-sm;
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    // Set small spacing
    .block + .block {
      padding-bottom: $block-padding-minor-xs;
    }

    // Set big spacing
    .block-intro-text {
      padding-bottom: $block-padding-minor-xs - 6px;
    }

    // Set custom padding if minor block follows major block
    .block-car-model-gallery,
    .block-form,
    .block-newspromo,
    .block-taxation,
    .block-teasers,
    .block-used-cars {
      + .block,
      + .block-download-links,
      + .block-intro-text,
      + .row {
        padding-top: $block-padding-major-xs;
      }
    }

    // Set custom padding if major block follows minor block
    .block,
    .block-intro-text,
    .row {
      + .block-car-model-gallery,
      + .block-form,
      + .block-newspromo,
      + .block-taxation,
      + .block-teasers,
      + .block-used-cars {
        margin-top: 21px;
      }
    }

    // Block formatting adjustment
    .block-intro-text + .block-text {
      padding-bottom: $block-padding-minor-xs - 7px;
    }

    // Set top-bottom margins
    .block-hero {
      + .container {
        // Remove spacing on first element in container
        > :first-child {
          &.block {
            padding-top: 0;
          }
        }

        // Add extra spacing of last item is a row
        > :last-child {
          &.row {
            .col-xs-12:last-of-type {
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    // Add extra spacing of last item for a regular content page
    .block-hero.is-job,
    .block-hero.is-team {
      + .container {
        > :last-child {
          &.block {
            padding-bottom: $block-padding-major-xs;
          }
        }
      }
    }

    // Exceptions - for some strange reason, there is no hero on a content page
    // when it should be
    header + .container {
      > :first-child {
        padding-top: $block-padding-major-xs;
      }
    }

    // Two of the same blocks followed by a different bloc
    .bg-white + .bg-white {
      + .bg-grey-paragraph {
        margin-top: $block-padding-major-xs;
      }
    }

    //
    .block-job-overview + .block-buttons {
      padding-top: $block-padding-major-xs;
    }
  }

  @include media-breakpoint-up(sm) {
    // Set small spacing
    .block + .block {
      padding-bottom: $block-padding-minor-sm;
    }

    .container > .block-download-links {
      padding-top: 0;
    }

    .block-intro-text {
      padding-bottom: $block-padding-minor-sm - 6px;
    }

    // Set big spacing
    .col-xs-12 {
      .block-download-links {
        padding-top: 0;
        padding-bottom: $block-padding-minor-sm;
      }
    }

    // Set custom padding if minor block follows major block
    .block-car-model-gallery,
    .block-form,
    .block-newspromo,
    .block-taxation,
    .block-teasers,
    .block-used-cars {
      + .block,
      + .block-download-links,
      + .block-intro-text,
      + .row {
        padding-top: $block-padding-major-sm;
      }
    }

    // Set custom padding if major block follows minor block
    .block,
    .block-intro-text,
    .row {
      + .block-car-model-gallery,
      + .block-form,
      + .block-newspromo,
      + .block-taxation,
      + .block-teasers,
      + .block-used-cars {
        margin-top: $block-padding-minor-sm + 2px;
      }
    }

    // Block formatting adjustment
    .block-intro-text + .block-text {
      padding-bottom: $block-padding-minor-sm - 7px;
    }

    // Set top-bottom margins (with hero)
    .block-hero {
      + .container {
        > :last-child {
          &.row {
            .col-xs-12:last-of-type {
              padding-bottom: $block-padding-minor-sm;
            }
          }
        }
      }
    }

    // Set top-bottom margins for content pages (with default text hero)
    .block-hero.is-default {
      + .container {
        > :first-child {
          padding-top: $block-padding-major-sm;
        }
      }
    }

    .block-hero.is-job,
    .block-hero.is-team {
      + .container {
        > :last-child {
          &.block {
            padding-bottom: $block-padding-major-sm;
          }
        }
      }
    }

    // Exceptions - for some strange reason, there is no hero on a content page
    // when it should be
    header + .container {
      > :first-child {
        padding-top: $block-padding-major-sm;
      }
    }

    // Two of the same blocks followed by a different bloc
    .bg-white + .bg-white {
      + .bg-grey-paragraph {
        margin-top: $block-padding-major-sm;
      }
    }

    //
    .block-job-overview + .block-buttons {
      padding-top: $block-padding-major-sm;
    }
  }
}
