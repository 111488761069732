@use '../abstracts' as *;

.social-media-links {
  display: flex;
  float: none;
  gap: 1rem;

  a {
    position: relative;
    display: block;
    filter: alpha(opacity=70);
    font-size: 2rem;
    opacity: 0.7;

    &:hover {
      filter: alpha(opacity=100);
      opacity: 1;
      text-decoration: none;

      .icon {
        filter: alpha(opacity=100);
        opacity: 1;
      }
    }
  }

  .social-media-item {
    padding: 0;

    a {
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 0;
      border: 1px solid transparent;
      border-radius: 0;
      background-color: $color-black;
      color: $color-white;
      font-size: inherit;
      opacity: 1;
      transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
        border-color 0.25s ease-in-out;

      &:hover {
        border-color: $color-black;
        background-color: $color-white;
        color: $color-black;
      }

      &:focus-visible {
        outline: revert;
        outline-color: $color-outline;
        outline-offset: 3px;
      }
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  @include media-breakpoint-up(sm) {
    margin: auto 0 0 auto;
  }
}
