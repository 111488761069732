/**
* @description Block - USPs
*
*/

.block-usps {
  &__subtitle {
    padding-bottom: 20px;
  }

  p {
    padding-bottom: 15px;
  }
}
