@use '../abstracts' as *;

/*

Utils are here for backwards compatibility purposes.
Please avoid using util classes.

*/

.float-left {
  float: left;
}

.hidden-lg {
  display: block;
}

@include media-breakpoint-up(lg) {
  .hidden-lg {
    display: none;
  }
}

.block {
  display: block;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

// Backgrounds

.bg-black {
  background-color: $color-black;
}

.bg-transparent-black {
  background-color: $color-transparent-black;
}

.bg-red {
  background-color: $color-red;
}

.bg-red-hover {
  background-color: $color-red-hover;
}

.bg-white {
  background-color: $color-white;
}

.bg-white-transparent {
  background-color: $color-white-transparent;
}

.bg-white-transparent-light {
  background-color: $color-white-transparent-light;
}

.bg-light-grey {
  background-color: $color-light-grey;
}

.bg-light-grey-hover {
  background-color: $color-light-grey-hover;
}

.bg-transparent-grey {
  background-color: $color-transparent-grey;
}

.bg-grey-medium {
  background-color: $color-grey-medium;
}

.bg-grey-medium-1 {
  background-color: $color-grey-medium-1;
}

.bg-grey-paragraph {
  background-color: $color-black;
}

.bg-dark-grey {
  background-color: $color-dark-grey;
}

.bg-transparent-light-grey {
  background-color: $color-transparent-light-grey;
}

.bg-transparent-medium-grey {
  background-color: $color-transparent-medium-grey;
}

.bg-deep-purple {
  background-color: $color-deep-purple;
}

.bg-indigo {
  background-color: $color-indigo;
}

.bg-blue {
  background-color: $color-blue;
}

.bg-cyan {
  background-color: $color-cyan;
}

.bg-teal {
  background-color: $color-teal;
}

.bg-green {
  background-color: $color-green;
}

//

.row-flex {
  display: flex;

  &::before,
  &::after {
    display: none;
  }

  > div {
    float: none;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-center-lg-up {
  @include media-breakpoint-up(lg) {
    justify-content: center;
  }
}

.justify-content-center-sm-up {
  @include media-breakpoint-up(sm) {
    justify-content: center;
  }
}

.justify-content-center-md-up {
  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}

.justify-content-center-xs-only {
  @include media-breakpoint-only(xs) {
    justify-content: center;
  }
}

@include flex-order-breakpoint(sm);
@include flex-order-breakpoint(lg);

// Grid

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@include media-breakpoint-up(sm) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@include media-breakpoint-up(md) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@include media-breakpoint-up(lg) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@include media-breakpoint-up(xl) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

//
.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

@include media-breakpoint-up(md) {
  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }
}

@include media-breakpoint-up(lg) {
  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }
}
