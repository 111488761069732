@use '../../abstracts' as *;

/**
* @description Block - Dealer intro
*
*/

.block-dealer-intro {
  &__title {
    &-logo,
    &-review {
      width: 150px;
    }

    &-logo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 10px;

      img {
        width: 100%;
        max-width: 200px;
        height: auto;
        max-height: 150px;
      }

      h6 {
        margin: auto;
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
      }
    }

    &-review {
      a {
        color: $color-dark-grey;
        font-size: 13px;
        line-height: 17px;
        text-decoration: underline;
        transition: color 200ms $ease-out-sine;

        &:focus,
        &:hover {
          color: $color-red;
        }

        b {
          font-weight: normal;
        }
      }

      p {
        &:last-child {
          padding-top: 23px;
          color: $color-grey-paragraph;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    &__title {
      display: flex;
      padding-top: $block-padding-major-xs;
      padding-bottom: ($block-padding-major-xs * 0.5) + 1px;
      border-bottom: 1px solid $color-border-light-grey;

      &-title {
        align-self: flex-start;
        margin: 0 auto 0 0;
      }

      &-review {
        align-self: flex-end;
        margin: 0 0 0 auto;

        &.no-logo {
          align-self: flex-start;
          margin: 0;
        }
      }
    }

    &__body {
      padding-top: ($block-padding-major-xs * 0.5);
      padding-bottom: $block-padding-major-xs - 11px;
      text-align: left;

      h1 {
        padding-bottom: ($block-padding-major-xs * 0.6);
      }

      h2 {
        padding-bottom: ($block-padding-major-xs * 0.5);
        text-align: left;
      }

      &-text {
        padding-bottom: ($block-padding-major-xs * 0.5);
      }
    }
  }

  h1 {
    padding-bottom: ($block-padding-major-xs * 0.6);
    text-align: left;
  }

  @include media-breakpoint-up(sm) {
    padding: 0 20px;

    &__container {
      display: flex;
      padding: $block-padding-major-sm 0;
    }

    &__title {
      min-width: 150px;
      flex-grow: 0;
      flex-shrink: 0;
      align-self: flex-start;
      padding-top: 30px;
      padding-right: 30px;

      &-logo + &-review {
        margin-top: 30px;
      }
    }

    &__body {
      flex-grow: 1;
      flex-shrink: 1;
      align-self: center;
      padding: 25px 30px;
      border-left: 1px solid $color-border-light-grey;
      text-align: left;

      h2,
      p {
        text-align: inherit;
      }

      h2 {
        margin-bottom: 30px;
      }

      &-text {
        margin-bottom: 30px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: 0 20px;

    &__title {
      padding-right: 50px;
    }

    &__body {
      padding-left: 50px;
    }

    .list.is-checklist {
      display: flex;
      flex-wrap: wrap;
      opacity: 0.9;

      .list__item {
        width: 50%;
      }
    }
  }
}
