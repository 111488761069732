@use '../../abstracts' as *;

.header-review {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  background-color: $color-light-grey;
  font-size: 14px;
  line-height: 24px;

  .header-locked & {
    background-color: #15151b;
  }

  > * + * {
    margin-left: 12px;
  }

  a {
    padding-right: 0;
    text-decoration: underline;

    .header-locked & {
      color: $color-white;
    }
  }

  &__powered-by {
    display: none;
    margin-left: 8px;
    color: $color-grey-paragraph;

    @include media-breakpoint-up(sm) {
      display: inline;
    }
  }

  .review-stars {
    .header-locked & {
      --color: #fff;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 30px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 50px;
    }
  }

  @include media-breakpoint-up(xl) {
    justify-content: flex-start;
  }
}
