@use '../../abstracts' as *;

/**
* @description Dealer map - Dealer info
*
*/

.dealer-info {
  h6 {
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  &__back {
    display: block;

    &-btn {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      padding: 11px 12px; // calc(var(--safe-area-inset-left) + 12px);
      border: 0;
      border-bottom: 1px solid $color-border-light-grey;
      background-color: $color-white;
      color: $color-dark-grey;
      cursor: pointer;
      font-family: inherit;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__address {
    padding-bottom: 15px;
    color: $color-grey-paragraph;
    font-size: 16px;
    line-height: 26px;
  }

  &__additional {
    flex: 0 0 100%;
    margin-top: 16px;
    color: $color-grey-paragraph;
    font-size: 16px;
    line-height: 26px;

    a {
      color: $color-grey-paragraph;
    }
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;

    &-text {
      flex-grow: 1;
      flex-shrink: 1;
      margin-top: -2px;
    }

    &-image {
      display: none;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-grey-paragraph;
    gap: 0 8px;

    &:visited {
      color: $color-grey-paragraph;
    }

    .icon {
      --size: 20px;

      flex-grow: 0;
      flex-shrink: 0;
      padding: 0;
    }

    span {
      flex-grow: 18;
      flex-shrink: 1;
      color: inherit;
    }

    &:active,
    &:hover {
      text-decoration: none;
    }

    &.is-small {
      i {
        &.icon-service-and-maintenance {
          font-size: 19px;
        }
      }
    }

    &.is-big {
      padding-top: 5px;
      white-space: nowrap;

      span {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  &__btn-group {
    display: flex;
    flex-wrap: wrap;

    &.is-primary {
      padding: 30px 0;
    }

    &.is-secondary {
      padding: 20px 0 10px;
      border-top: 1px solid $color-border-light-grey;
      border-bottom: 1px solid $color-border-light-grey;

      .btn,
      .dealer-info__btn {
        min-width: auto;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 20px;
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 16px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__opening-times {
    display: flex;
    padding-top: 20px;
    padding-bottom: 27px;
    gap: 8px;

    &__label {
      padding-bottom: 10px;
      color: $color-dark-grey;
    }

    i {
      --size: 16px;

      flex-grow: 0;
      flex-shrink: 0;
    }

    ul {
      flex-grow: 1;
      flex-shrink: 1;
    }

    li {
      display: flex;
      color: $color-grey-paragraph;
      font-size: 13px;
      line-height: 16px;

      &.extra {
        display: inline-block;
        padding-top: 10px;
        color: $color-dark-grey;
        font-weight: bold;
      }
    }

    span {
      width: 100px;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 10px;
    }
  }

  @include media-breakpoint-only(xs) {
    padding: 30px 15px; // calc(var(--safe-area-inset-left) + 15px);

    &__back {
      position: absolute;
      top: -250px;
      right: 0;
      left: 0;

      &-btn {
        border-top: 1px solid $color-border-light-grey;
        border-bottom: 0;
      }
    }
  }

  @include media-breakpoint-only-xxxs {
    &__btn-group {
      &.is-primary {
        .btn {
          width: 100%;
          margin-bottom: 15px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  //
  @include media-breakpoint-between-xxs-xs {
    display: flex;
    flex-wrap: wrap;

    &__title,
    &__opening-times {
      width: 50%;
    }

    &__title {
      padding-bottom: 27px;
    }

    &__opening-times {
      padding-top: 0;
    }

    &__btn-group {
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    height: inherit;
    padding: 27px 20px 30px; // calc(var(--safe-area-inset-left) + 20px);

    &__title {
      padding-bottom: 30px;
    }

    &__opening-times {
      padding-top: 0;
      margin-top: 10px * -1;
    }

    &.is-multiple {
      .dealer-info {
        &__title,
        &__opening-times {
          padding-left: 45px;
        }

        &__title {
          position: relative;

          &::before {
            position: absolute;
            top: -8px;
            left: 0;
            display: flex;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            border: 1px solid currentcolor;
            border-radius: 100%;
            background-color: $color-white;
            color: $color-black;
            content: attr(data-number);
            font-weight: bold;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__title {
      &-image {
        display: block;
        overflow: hidden;
        max-width: 165px;
        max-height: 130px;
        flex-grow: 0;
        flex-shrink: 1;
        margin-left: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__btn {
      &.is-small {
        i {
          color: $color-dark-grey;
          font-size: 16px;

          &.icon-service-and-maintenance {
            font-size: 23px;
          }
        }
      }
    }
  }
}
