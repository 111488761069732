@use '../../abstracts' as *;

/**
* @description Transition - News promo
*
*/

.filter-list {
  .icon {
    transition: transform 250ms $ease-in-out-cubic;
  }
}
