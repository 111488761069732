@use '../../abstracts' as *;

/**
* @description Forms - Number plate
*
*/

.form-number-plate {
  display: flex;
  flex-direction: column;
  padding: 24px 30px 30px;
  border: 1px solid $color-border-light-grey;
  gap: 28px;

  @include media-breakpoint-up(md) {
    flex-flow: nowrap;
    align-items: flex-end;
  }

  &__field {
    max-inline-size: 284px;

    &-label {
      color: $color-grey-paragraph;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin-block-end: 8px;
    }

    &-input {
      display: flex;

      input {
        width: 100%;
        padding: 10px 12px;
        border: 0;
        background-color: transparent;
        color: $color-black;
        font-family: inherit;
        font-size: 16px;

        &:focus-visible {
          outline: 2px solid $color-outline;
          outline-offset: -2px;
        }
      }

      &::before,
      &::after {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.is-nl-plate {
        background-color: $color-nl-number-plate-yellow;

        &::before {
          height: 48px;
          aspect-ratio: 1;
          background-color: $color-nl-number-plate-blue;
          color: $color-white;
          content: 'NL';
          font-size: 22px;
          font-weight: 500;
        }

        input {
          font-size: 22px;
          font-weight: 500;
          text-align: center;
          text-transform: uppercase;
        }
      }

      &.is-distance {
        border: 1px solid $color-light-grey;

        &::after {
          height: 46px;
          aspect-ratio: 1;
          border-inline-start: 1px solid $color-light-grey;
          color: $color-black;
          content: 'KM';
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex: 1 1 0;
      padding-block-end: 0;

      &:last-of-type {
        flex-shrink: 0;
      }
    }
  }

  &__btn {
    margin-right: 0;
    background-color: $color-light-grey-hover;

    @include media-breakpoint-up(sm) {
      margin-top: 7px;
      margin-right: 0;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
      margin-right: 0;
    }
  }
}
