@use '../../abstracts' as *;

/**
* @description Block - Download files
*
*/

.block-download-files {
  padding: $block-padding-major-xs - 5px 0 20px;

  > h2 {
    margin-bottom: 50px;
  }

  &__title {
    padding-bottom: 23px;
  }

  .item-download-file {
    padding-bottom: 30px;
  }

  @include media-breakpoint-up(sm) {
    padding: $block-padding-major-sm - 5px 0 $block-padding-minor-sm;

    &__title {
      padding-bottom: $block-padding-minor-sm - 8px;
    }

    .item-download-file {
      padding-bottom: $block-padding-minor-sm;
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: $block-padding-major-md;

    .item-download-file {
      padding-bottom: 0;
    }
  }
}
