/**
* @description UI - Accordion
*
*/

.accordion {
  overflow: hidden;
  height: auto;
  max-height: 0;

  &.is-accordion-visible {
    max-height: 300px;
  }
}
