@use '../../abstracts' as *;

/**
* @description Item - Review detail
*
*/

.item-review-detail {
  text-align: center;

  &__description {
    padding-top: 10px;
    padding-bottom: 10px;
    color: $color-grey-paragraph;
    font-size: 16px;
    line-height: 26px;
  }

  &__author {
    padding-bottom: 15px;
    color: $color-grey-paragraph;
    font-size: 13px;
    line-height: 16px;

    span {
      display: block;
      padding-top: 7px;
    }
  }

  &__link {
    display: inline-flex;
    color: $color-dark-grey;
    font-size: 16px;

    &::after {
      --size: 20px;
      --icon: var(--icon-arrow-right);
      --color: currentcolor;

      flex-shrink: 0;
      content: '';

      @include icon;
    }

    > span {
      line-height: 15px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
