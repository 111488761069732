@use '../../abstracts' as *;

/**
* @description Dealer map - Side panel
*
*/

.side-panel {
  overflow: hidden;
  background-color: $color-white;

  &__container {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    transform: translate3d(0, 0, 0);

    &.show-page {
      transform: translate3d(-50%, 0, 0);
    }
  }

  &__page {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__scrollbar-track {
    position: absolute;
    top: 3px;
    right: 0;
    bottom: 3px;
    width: 11px;
    height: calc(100% - 6px);
    background-color: $color-white;

    &::before {
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: 0;
      display: block;
      border-left: 1px solid $color-border-light-grey;
      content: '';
    }
  }

  &__scrollbar-thumb {
    position: absolute;
    top: 0;
    right: 3px;
    display: block;
    width: 4px;
    border-radius: 3px;
    background-color: $color-red;
  }

  @include media-breakpoint-up(sm) {
    z-index: 2;
    height: 100%;
    overflow-y: auto;
    transform: translate3d(-101%, 0, 0);

    &.show-panel {
      transform: translate3d(0, 0, 0);
    }
  }

  @include media-breakpoint-only(xs) {
    width: 100%;

    &__container {
      width: 200%;
    }

    &__page {
      width: 50%;
    }
  }

  @include media-breakpoint-only(sm) {
    width: 384px;

    &__container {
      width: 768px;
    }

    &__page {
      width: 384px;
    }
  }

  @include media-breakpoint-up(md) {
    width: 530px;

    &__container {
      width: 1060px;
    }

    &__page {
      width: 530px;
    }
  }
}
