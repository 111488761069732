@use '../../abstracts' as *;

/**
* @description UI - Social toolbar
*
*/

.social-toolbar {
  display: flex;
  margin: 0 auto;

  &__social {
    &-date,
    &-share {
      color: $color-grey-paragraph;
      font-size: 13px;
    }
  }

  &.is-news {
    .social-toolbar {
      &__social {
        &-date {
          padding-right: 28px;
          margin: auto 0 auto auto;
        }

        &-share {
          padding-right: 13px;
          margin: auto 0;
        }

        &-links {
          margin: auto auto auto 0;
          justify-self: normal;
        }
      }
    }
  }

  &.is-promo {
    flex-direction: column;
    align-items: center;

    .social-toolbar {
      &__social {
        display: flex;
        align-items: center;

        &-share {
          padding-right: 13px;
        }
      }
    }
  }

  &.is-job {
    .social-toolbar {
      &__icons {
        text-align: center;
      }

      &__social {
        display: inline-flex;
        align-items: center;

        &-share {
          margin-right: 15px;
          margin-left: auto;
        }

        &-links {
          margin-right: auto;
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    &.is-promo {
      .social-toolbar {
        &__social {
          padding-top: 20px;
        }

        > :first-child {
          padding-top: 0;
        }
      }
    }

    &.is-job {
      padding-top: 10px;

      .social-toolbar {
        &__social {
          padding-top: 20px;
          padding-bottom: $block-padding-minor-xs - 3px;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &.is-news {
      .social-toolbar {
        &__social {
          &-date,
          &-share {
            font-size: 16px;
          }

          &-date {
            padding-right: 48px;
          }

          &-share {
            padding-right: 18px;
          }

          &-links {
            margin: auto auto auto 0;
            justify-self: normal;
          }
        }
      }
    }

    &.is-promo {
      flex-direction: row;
      align-items: center;

      .social-toolbar {
        &__promo {
          margin: auto 0 auto auto;

          .tooltip-promo__button {
            padding-bottom: 0;

            &-text {
              font-size: 16px;
            }

            &-icon {
              font-size: 19px;
            }
          }
        }

        &__social {
          margin: auto auto auto 0;

          &-share {
            padding-right: 18px;
            padding-left: 48px;
            font-size: 16px;
          }
        }
      }
    }

    &.is-job {
      align-items: center;
      justify-content: center;
      padding-top: 19px;
      padding-bottom: $block-padding-minor-sm - 14px;

      span,
      .social-toolbar__social-share {
        font-size: 16px;
        line-height: 26px;
      }

      .social-toolbar__social {
        margin-left: 30px;
      }
    }
  }
}
