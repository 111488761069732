@use '../../abstracts' as *;

/**
* @description UI - Opening hours exception
*
*/

.opening-hours-exception {
  display: flex;

  i {
    margin: 0 0 auto auto;
    color: $color-red;
    font-size: 20px;
  }

  p {
    padding-left: 7px;
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
  }

  @include media-breakpoint-only(xs) {
    align-items: center;
    padding-top: 0;
    padding-bottom: $block-padding-minor-xs;
    margin-top: -9px;

    i {
      flex-grow: 0;
      flex-shrink: 0;
    }

    p {
      flex-grow: 1;
      flex-shrink: 1;
      padding-top: 2px;
    }
  }

  @include media-breakpoint-up(sm) {
    width: 100%;
    padding-top: 0;
    padding-bottom: $block-padding-minor-sm;
    text-align: center;

    p {
      margin: auto auto auto 0;
    }
  }
}
