@use '../../abstracts' as *;

/**
* @description Item - Download file
*
*/

.item-download-file {
  &__image {
    img {
      width: 100%;
    }
  }

  &__body {
    h5 {
      padding: 17px 0;
      border-bottom: 1px solid $color-border-light-grey;
      margin: 0;
      font-size: 16px;
      line-height: 18px;
    }

    a {
      display: flex;
      align-items: center;
      padding: 13px 0;
      border-bottom: 1px solid $color-border-light-grey;
      color: $color-grey-paragraph;
      font-size: 16px;
      gap: 8px;
      line-height: 26px;

      .icon {
        flex-grow: 0;
        flex-shrink: 0;
      }

      span {
        flex-grow: 1;
        flex-shrink: 1;
      }

      &:focus,
      &:hover {
        color: mix($color-grey-paragraph, #fff, 50%);
        text-decoration: none;

        .icon {
          color: inherit;
        }
      }
    }
  }
}
