@use '../../abstracts' as *;

/**
* @description Block - Car model gallery
*
*/

.block-car-model-gallery {
  padding: $block-padding-major-xs - 6px 0 0;

  &__title,
  h1 {
    padding-bottom: 30px;
  }

  &__subnav {
    display: block;
    text-align: center;
  }

  .gallery {
    max-width: 100vw;
    margin: 0 auto;
  }

  @include media-breakpoint-up(sm) {
    padding: $block-padding-major-sm - 7px 0 0;

    &__title {
      padding-bottom: $block-padding-minor-sm - 10px;
    }
  }
}
