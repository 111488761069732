@use '../../abstracts' as *;

/**
* @description Block - Used car
*
*/

.block-used-cars {
  padding: $block-padding-major-xs - 5px 0 $block-padding-major-xs;

  &.has-no-padding {
    padding-top: 0;
  }

  &__title {
    padding-bottom: 25px;
  }

  h1 {
    padding-bottom: ($block-padding-major-xs * 0.6);
  }

  &__intro {
    color: $color-grey-paragraph;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  &__usps {
    padding-block: 40px;

    ul {
      display: inline-flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      gap: 20px 0;
    }

    li {
      text-align: center;
    }

    span,
    p {
      margin: 0 auto;
    }

    p {
      color: $color-grey-paragraph;
    }

    b {
      font-weight: normal;
    }

    .icon-svg {
      width: 80px;
      height: 80px;
      margin-inline: auto;
    }
  }

  &__footer {
    padding-top: 15px;
    text-align: center;

    &.has-no-padding {
      padding-top: 0;
    }
  }

  &.bg-white {
    .col-xs-12 {
      margin-bottom: 15px;
    }

    @include media-breakpoint-up(sm) {
      .col-xs-12 {
        margin-bottom: 30px;
      }

      .block-used-cars__footer {
        padding-top: 20px;
      }
    }
  }

  .block-margin-bottom {
    text-align: center;
  }

  @include media-breakpoint-only(xs) {
    &__usps {
      ul {
        width: 100%;
      }

      li {
        width: 50%;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding: $block-padding-major-sm - 6px 0 $block-padding-major-sm;

    &__usps {
      text-align: center;

      ul {
        margin: 0 auto;
      }

      li {
        padding: 0 ($block-padding-minor-sm * 0.5);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__intro {
      max-width: 945px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-up(sm) {
    .block-used-cars {
      &__title {
        padding-bottom: $block-padding-minor-sm - 5px;

        + .block-used-cars__intro {
          margin-top: -27px;
        }
      }

      &__footer {
        padding-top: $block-padding-minor-sm;

        &.has-no-padding {
          padding-top: 0;
        }
      }
    }

    .row-flex {
      justify-content: center;
    }
  }
}
