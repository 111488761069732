@use '../abstracts' as *;

/**
* @description Abstract - Layout
*
*/

// We use the --vh unit for iOS so that we always get the correct
// viewport height
:root {
  --vh: 1vh;
}

html,
body {
  min-height: 100vh;
  overflow-x: hidden;

  --test-case: 1;
}

body {
  position: relative;
  width: 100%;
  margin: 0;
  margin-bottom: 0;
  background: #fff;
  background-color: #fff;
  color: $color-black;
  direction: ltr;
  font-family: Toyota, arial, helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  overflow-y: auto;

  &.is-locked,
  &.header-locked {
    position: fixed;
    left: 0;
    padding-right: var(--scrollbar-width);
    overflow-x: hidden;
    overflow-y: hidden;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 104px;
  }
}

.container {
  &::after {
    display: table;
    clear: both;
    content: ' ';
  }

  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  &::before {
    display: table;
    content: ' ';
  }
}

.row {
  &::after {
    display: table;
    clear: both;
    content: ' ';
  }

  &::before {
    display: table;
    content: ' ';
  }

  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-sm-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-sm-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-sm-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;

  img {
    max-width: 100%;
    margin: 0 auto;
  }
}

// For blocks that span full width with a colour
.is-full-width {
  .container > & {
    @include media-breakpoint-only(xs) {
      margin-right: -15px;
      margin-left: -15px;
    }

    @include media-breakpoint-only(sm) {
      margin-right: -360px;
      margin-left: -360px;
    }

    @include media-breakpoint-only(md) {
      margin-right: -470px;
      margin-left: -470px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: calc(-50% - 570px);
      margin-left: calc(-50% - 570px);
    }
  }
}

@include media-breakpoint-up(sm) {
  .container {
    width: 750px;
  }

  .col-sm-4 {
    width: 33.3333%;
    float: left;
  }

  .col-sm-6 {
    width: 50%;
    float: left;
  }

  .col-sm-8 {
    width: 66.6667%;
    float: left;
  }
}

@include media-breakpoint-up(md) {
  .container {
    width: 970px;
  }

  .col-md-3 {
    width: 25%;
    float: left;
  }

  .col-md-4 {
    width: 33.3333%;
    float: left;
  }

  .col-md-6 {
    width: 50%;
    float: left;
  }

  .col-md-9 {
    width: 75%;
    float: left;
  }

  .col-xs-12.col-sm-6 {
    img {
      width: 100%;
    }
  }

  .row {
    padding-bottom: 0;
  }
}

@include media-breakpoint-up(lg) {
  .container {
    width: 1170px;
  }

  .col-lg-3 {
    width: 25%;
    float: left;
  }

  .col-lg-4 {
    width: 33.3333%;
    float: left;
  }
}
