@use '../../abstracts' as *;

.dropdown {
  position: relative;
  display: flex;
  height: 46px;
  border-radius: 4px;
  background-color: #f0f0f0;
  font-size: 1.1rem;
  transition: background-color 0.25s ease-in-out;

  &:is(:hover, :focus-visible) {
    background-color: #cecfd0;
  }

  &.open {
    border-radius: 4px 4px 0 0;
    background: #cecfd0;
  }
}

.dropdown-toggle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  padding-inline: 18px;

  &:hover {
    text-decoration: none;
  }

  .open & {
    border-radius: 4px 4px 0 0;
    box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 5%);

    .icon {
      transform: rotate(180deg);
    }
  }
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  display: none;
  overflow: hidden;
  width: 100%;
  min-width: 160px;
  padding: 0;
  border: none;
  border-radius: 0 0 4px 4px;
  margin: 0;
  background-clip: padding-box;
  background-color: #f0f0f0;
  font-size: 15px;
  list-style: none;
  text-align: left;

  .open & {
    display: block;
    max-height: 200px;
    overflow-y: auto;
  }

  li {
    position: relative;
    background: #f0f0f0;
    box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 5%);

    .icon-ok {
      --size: 20px;
      --color: #fff;
      --icon: var(--icon-check);

      position: absolute;
      top: 50%;
      right: 18px;
      display: none;
      width: 20px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      aspect-ratio: 1;
      background-color: $color-black;
      transform: translateY(-50%);

      &::before {
        content: '';

        @include icon;
      }
    }

    > .active + .icon-ok {
      display: flex;
    }
  }

  a {
    display: flex;
    padding-block: 10px;
    padding-inline: 18px;
    transition: background-color 0.25s ease-in-out;

    &:hover {
      text-decoration: none;
    }

    &:is(:hover, :focus-visible) {
      background-color: #cecfd0;
    }
  }
}
