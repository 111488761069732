@use '../../abstracts' as *;

/**
* @description Block - Download links
*
*/

.block-download-links {
  padding: 0 0 $block-padding-major-xs - 7px;

  &__title {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-border-light-grey;
    margin-bottom: 13px;

    h5 {
      flex-grow: 1;
      flex-shrink: 1;
      color: $color-dark-grey;
      font-size: 19px;
      font-weight: bold;
      line-height: 21px;
    }

    i {
      flex-grow: 0;
      flex-shrink: 0;
      color: $color-red;
      font-family: Toyota-icon-custom, sans-serif;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: $block-padding-major-sm - 5px 0 $block-padding-major-sm - 3px;

    &__title {
      padding-bottom: 26px;
      margin-bottom: 33px;
    }
  }
}
