/**
* @description UI - You Tube iframe video
*
*/

.youtube {
  position: relative;

  .block > &:last-child {
    padding-bottom: 56.25%;
  }

  iframe,
  a {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    inset: 0;
  }
}
