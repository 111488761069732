@use '../abstracts' as *;

// new version

.btn,
a.btn {
  --color: 0 0% 100%;
  --background-color: 240 9% 17%;
  --opacity: 1;

  display: inline-flex;
  align-items: center;
  padding: 12px 24px;
  border: none;
  margin: 0;
  background-color: hsl(var(--background-color) / var(--opacity));
  color: hsl(var(--color));
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  gap: 0 8px;
  line-height: 1.5;
  transition-duration: 0.25s;
  transition-property: color, background-color, box-shadow, border-color;
  transition-timing-function: ease-in-out;
  vertical-align: middle;

  &:hover {
    --opacity: 0.8;

    text-decoration: none;
  }

  &:active {
    --opacity: 1;
  }

  &:focus-visible {
    outline: 2px solid $color-outline;
    outline-offset: 2px;
  }

  &:is(:disabled, .is-disabled) {
    --background-color: 0deg 0% 89%;

    cursor: default;

    &:hover {
      --opacity: 1;
    }
  }

  &--secondary {
    --color: 240 9% 17%;
    --background-color: transparent;
    --border-color: 240 9% 17%;

    box-shadow: inset 0 0 0 1px hsl(var(--border-color) / var(--opacity));

    &:is(:hover, :active) {
      --color: 0 0% 100%;
      --background-color: 240 9% 17%;
      --border-color: transparent;
    }

    &:is(:disabled, .is-disabled) {
      --color: 0 0% 89%;
      --background-color: transparent;
      --border-color: 0 0% 89%;
    }
  }

  &--tertiary {
    --color: 240 9% 17%;
    --background-color: transparent;
    --border-color: 240 9% 17%;

    border-block-end: 1px solid hsl(var(--border-color) / var(--opacity));
    padding-inline: 0;

    &:is(:disabled, .is-disabled) {
      --color: 0 0% 89%;
      --background-color: transparent;
      --border-color: 0 0% 89%;
    }

    &:hover {
      --opacity: 0.8;
    }

    &:active {
      --opacity: 1;
    }
  }

  > .icon {
    --size: 20px;

    flex-shrink: 0;
  }
}

.block.block-buttons {
  // container queries not fully supported but it's a nice feature for browsers that supports it
  container-type: inline-size;

  > :first-child {
    padding-block-end: 12px;
  }

  > :not(:first-child.btn) {
    --color: 240 9% 17%;
    --background-color: transparent;
    --border-color: 240 9% 17%;

    box-shadow: inset 0 0 0 1px hsl(var(--border-color) / var(--opacity));

    &:is(:hover, :active) {
      --color: 0 0% 100%;
      --background-color: 240 9% 17%;
      --border-color: transparent;
    }

    &:is(:disabled, .is-disabled) {
      --color: 0 0% 89%;
      --background-color: transparent;
      --border-color: 0 0% 89%;
    }
  }

  > .btn {
    @media (width <= 599px) {
      flex-basis: 100%;
      justify-content: center;
      text-align: center;
    }

    @container (max-width: 24em) {
      flex-basis: 100%;
      justify-content: center;
      text-align: center;
    }
  }
}

.block-buttons,
.block.block-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
