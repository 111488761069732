@use '../abstracts' as *;

/**
* @description Page - Car Model
*
*/

.car-model-page {
  .block-intro-text {
    padding-bottom: $block-padding-major-xs - 6px;
  }

  .bg-white + .bg-white {
    padding-top: 0;
  }

  .bg-light-grey + .bg-light-grey {
    padding-top: 0;
  }

  .block-intro-text + .bg-white {
    padding-top: 0;
  }

  .block-car-model-reviews + .block-car-model-gallery {
    margin-top: -5px;
  }

  .bg-white + .block-car-model-gallery {
    padding-top: 0;
  }

  // If the reviews are empty... adjust padding
  .block-car-model-reviews:empty {
    + .block-download-files {
      padding-top: $block-padding-major-xs - 2;
    }
  }

  @include media-breakpoint-up(sm) {
    .block-intro-text {
      padding-top: $block-padding-minor-sm;
      padding-bottom: $block-padding-major-sm - 6px;
    }

    .block-taxation + .block-car-model-gallery,
    .block-car-model-reviews + .block-car-model-gallery {
      margin-top: -5px;
    }

    .block-car-model-gallery {
      + .block-download-files,
      + .block-car-model-reviews {
        padding-top: $block-padding-major-sm - 6px;
      }
    }

    // If the reviews are empty... adjust padding
    .block-car-model-reviews:empty {
      + .block-download-files {
        padding-top: $block-padding-major-sm - 2;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .block-intro-text + .block-taxation {
      margin-top: -7px;
    }
  }
}
