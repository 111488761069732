@use './abstracts/mixins.icon' as *;

.slick-slider {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  touch-action: pan-y;
  user-select: none;
}

.slick-arrow {
  --size: 32px;
  --icon: var(--icon-chevron-right);
  --icon-top: 50%;
  --icon-left: 50%;
  --color: #fff;

  position: absolute;
  z-index: 1;
  top: 50%;
  display: none;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: rgb(40 40 48 / 85%);
  cursor: pointer;
  font-size: 0;
  transition: background-color 0.3s linear;

  @include icon-before;

  &::before {
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: #282830;
  }

  &.slick-next {
    right: 10px;
  }

  &.slick-prev {
    left: 10px;

    &::before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

.slick-thumb {
  height: 160px;
}

.slick-initialized {
  .slick-slide {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.slick-track {
  top: 0;
  left: 0;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
}

.slick-slide {
  display: none;
  height: 100%;
  min-height: 1px;
  float: left;

  > div {
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-list {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}

.slick-track::after,
.slick-track::before {
  display: table;
  content: '';
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

// MODAL FROM OLD IMPORT

.modal-footer::after {
  clear: both;
}

.modal-open {
  overflow: hidden;
}

.modal,
.modal-backdrop {
  inset: 0;
}

.modal {
  position: fixed;
  z-index: 1050;
  display: none;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  background-clip: padding-box;
  background-color: #fff;
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-header {
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.4286;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  text-align: right;
}

.modal-footer::after,
.modal-footer::before {
  display: table;
  content: ' ';
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  overflow: scroll;
  width: 50px;
  height: 50px;
}

@media (width >= 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  }

  .modal-sm {
    width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg {
    width: 900px;
  }
}
