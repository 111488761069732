@use '../../abstracts' as *;

/**
* @description UI - Filter list
*
*/

.filter-list {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  border: 1px solid $color-border-light-grey;
  border-radius: 4px;
  margin-bottom: $block-padding-minor-xs;
  background-color: $color-white;

  &__title {
    padding-right: 20px;
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
  }

  &__checkbox {
    position: relative;
    display: inline-flex;
    margin: 0;
    cursor: pointer;
    font-weight: bold;

    &-input {
      display: none;

      &:checked + .icon {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }
    }

    .icon {
      position: absolute;
      z-index: 2;
      top: 3px;
      left: 0;
      pointer-events: none;
      transform: scale3d(0, 0, 0);
    }

    .icon-ok {
      --size: 20px;
      --color: #{$color-black};
      --icon: var(--icon-check);

      @include icon;
    }

    &-text {
      position: relative;
      display: block;
      padding: 0 20px 0 29px;
      color: $color-grey-paragraph;
      font-size: 16px;
      font-weight: normal;
      line-height: 26px;

      &::before {
        position: absolute;
        z-index: 1;
        top: 3px;
        left: 0;
        display: block;
        width: 20px;
        height: 20px;
        border: 2px solid $color-light-grey-hover;
        border-radius: 4px;
        background-color: $color-white;
        content: '';
      }
    }

    &:last-of-type {
      .filter-list__checkbox-text {
        padding-right: 0;
      }
    }
  }

  @include media-breakpoint-only-xxxs {
    &.has-all,
    &.has-only-checkbox {
      padding-right: 10px;

      label {
        padding-right: 15px;
      }

      .filter-list {
        &__title {
          padding-right: 10px;
        }

        &__checkbox {
          &-text {
            padding-right: 0;
          }
        }
      }
    }

    &.has-only-dropdown {
      flex-direction: column;

      label {
        align-self: flex-start;
      }

      .dropdown {
        margin-top: 17px;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    .dropdown {
      width: 100%;
    }

    &.has-all {
      flex-wrap: wrap;

      .dropdown {
        margin-top: 17px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
    margin-bottom: 50px;

    &__checkbox {
      &:last-of-type {
        padding-right: 50px;
      }
    }

    .dropdown {
      flex-grow: 1;
      flex-shrink: 1;
      margin: auto auto auto 0;
    }
  }

  @include media-breakpoint-only(md) {
    &.has-only-dropdown {
      .dropdown {
        margin-right: 463px;
      }
    }

    &.has-all {
      .dropdown {
        max-width: 433px;
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.has-only-dropdown {
      .dropdown {
        margin-right: 566px;
      }
    }

    &.has-all {
      .dropdown {
        max-width: 535px;
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}

// -
.form-v2 {
  label {
    padding-right: 20px;
    margin-bottom: 0;
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
  }

  .dropdown {
    width: 100%;
    margin-bottom: 0;
  }

  @include media-breakpoint-only(xs) {
    label {
      padding-right: 20px;
    }

    .dropdown {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
