@use '../../abstracts' as *;

/**
* @description Transition - Car Model page
*
*/

.block-car-model {
  &__price-disclaimer,
  &__subtitle a {
    text-decoration: underline;
    transition: color 150ms $ease-in-out-cubic;

    &:active,
    &:hover {
      color: $color-red;
    }
  }
}

.form-number-plate {
  &__btn {
    transition: background-color 300ms $ease-in-out-cubic;

    &:active,
    &:hover {
      background-color: $color-light-grey-hover;
    }
  }
}

//
.gallery {
  &__link {
    img {
      transition: transform 250ms $ease-out-quad;
    }

    &:active,
    &:hover {
      img {
        transform: scale3d(1.05, 1.05, 1.05);
      }
    }
  }
}
