@use '../../abstracts' as *;

/**
* @description Block - default
*
*/

.block {
  display: block;
  width: 100%;
  padding-bottom: $block-padding-minor-xs;

  > :first-child {
    padding-bottom: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-only(sm) {
    padding-bottom: $block-padding-minor-sm;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: $block-padding-minor-md;
  }
}
