@use '../../abstracts' as *;

/**
* @description Block - Teasers
*
*/

.block-teasers {
  $self: &;

  padding-top: $block-padding-major-xs - 6px;
  padding-bottom: $block-padding-minor-xs - 17px;

  &__title {
    margin-bottom: $block-padding-minor-xs;
    color: $color-white;
  }

  &__link {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-bottom: $block-padding-minor-xs;
    background-color: $color-white;
    transition: background-color 0.2s ease-in-out;

    // TOOD: Move this into separate transitions CSS
    &:focus,
    &:hover {
      background-color: #15151b;
      text-decoration: none;

      img {
        transform: scale3d(1.05, 1.05, 1.05);
      }

      #{$self}__content,
      #{$self}__content .justify-end {
        color: $color-white;
      }
    }
  }

  &__image {
    overflow: hidden;
    flex: 0 0 auto;
    margin-bottom: 14px;

    img {
      width: 100%;
      height: auto;
      transition: transform 250ms $ease-out-quad;
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 14px;
    color: $color-black;
    gap: 14px;
    transition: color 0.2s ease-in-out;

    strong {
      display: block;
      font-size: 17px;
      line-height: 18px;
    }

    .justify-end {
      margin-block-start: auto;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
  }

  .row-flex {
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(sm) {
    padding-top: $block-padding-major-md - 7px;
    padding-bottom: $block-padding-major-md - 7px;

    &__link {
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: $block-padding-minor-sm - 6px;
    }

    &__image {
      img {
        overflow: hidden;
        height: 99px;
        object-fit: cover;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__image {
      img {
        height: 162px;
      }
    }
  }
}
