@use '../../abstracts' as *;

/**
* @description Block - Title
*
*/

.block-title {
  &.is-align-left {
    text-align: left;
  }

  &,
  &.is-align-center {
    text-align: center;
  }

  h2,
  h4 {
    color: $color-dark-grey;
    text-align: inherit;
  }

  h2 + h4 {
    margin-top: 10px;
  }

  @include media-breakpoint-up(md) {
    h2 + h4 {
      margin-top: 20px;
    }
  }
}
