@use '../abstracts' as *;

html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  color: $color-black;
  font-family: sans-serif;
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  border: 0;
  border-radius: 2px;
  border-radius: 0;
  backface-visibility: hidden;
  font-size: 0;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);
  vertical-align: middle;
}

svg {
  &:not(:root) {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
span,
label,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  color: $color-black;
}

p {
  text-indent: 0;
}

article {
  display: block;

  img {
    border-radius: 2px;
  }
}

aside {
  display: block;
}

footer {
  display: block;
}

header {
  display: block;
}

nav {
  display: block;
}

section {
  display: block;
}
