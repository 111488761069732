@use '../../abstracts' as *;

/**
* @description Dealer map - Dealer item
*
*/

.dealer-item {
  display: flex;
  width: 100%;
  border-top: 1px solid $color-border-light-grey;
  cursor: pointer;

  &__image {
    display: none;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;

    &::before,
    .icon-angle-right {
      position: absolute;
    }

    &::before {
      top: 30px;
      left: 20px;
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: center;
      border-radius: 100%;
      background-color: $color-black;
      color: $color-white;
      content: attr(data-number);
      font-size: 14px;
      line-height: 24px;
    }

    .icon-angle-right {
      top: 29px;
      font-size: 24px;
      line-height: 18px;
    }
  }

  h6 {
    padding: 7px 35px 5px 0;
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  p {
    padding-bottom: 15px;
    color: $color-grey-paragraph;
    font-size: 16px;
    line-height: 26px;
  }

  .dealer-info__btn {
    margin-top: auto;
    justify-self: flex-end;
  }

  &__distance {
    position: absolute;
    right: 25px;
    bottom: 25px;
    color: $color-grey-paragraph;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
  }

  @include media-breakpoint-only(xs) {
    &__body {
      padding: 20px 15px 20px 65px;

      // padding-left: calc(var(--safe-area-inset-left) + 65px);

      &::before {
        left: calc(var(--safe-area-inset-left) + 15px);
      }

      .icon-angle-right {
        right: 7px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    height: 216px;
    cursor: pointer;

    &:first-of-type {
      border-top: 0;
    }

    &__body {
      padding: 20px 20px 20px 65px;

      // padding-left: calc(var(--safe-area-inset-left) + 65px);

      &::before {
        left: 20px;

        // left: calc(var(--safe-area-inset-left) + 20px);
      }

      .icon-angle-right {
        right: 18px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__image {
      display: block;
      width: 165px;
      height: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      object-fit: cover;
    }
  }
}
