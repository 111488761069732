@use '../../abstracts' as *;

/**
* @description UI - Pagination
*
*/

.pagination {
  > ul {
    display: flex;
    justify-content: center;
  }

  .page-link {
    display: flex;
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid $color-grey;
    border-radius: 0;
    border-right: 0;
    margin: 0;
    background-color: $color-white;
    background-image: none;
    box-shadow: none;
    color: $color-black;
    font-size: 14px;
    text-decoration: none;

    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }

    i {
      --size: 24px;

      color: $color-black;
    }
  }

  .page-item {
    &.disabled {
      .page-link {
        color: $color-pagination-disabled;

        i {
          color: inherit;
        }

        &:active,
        &:hover {
          background-color: $color-white;
          color: $color-pagination-disabled;
          cursor: not-allowed;
        }
      }
    }

    &.active {
      .page-link {
        border-color: $color-black;
        background-color: $color-black;
        color: $color-white;
      }
    }

    &:first-of-type {
      .page-link {
        overflow: hidden;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }
    }

    &:last-of-type {
      .page-link {
        border-right: 1px solid $color-grey;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }
    }
  }
}
