@use '../abstracts' as *;

p {
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  text-indent: 0;

  a {
    color: $color-black;
    text-decoration: underline;

    &:hover {
      color: $color-red-hover;
    }
  }

  &:first-child {
    text-indent: 0;
  }
}

b {
  font-style: normal;
  font-weight: 700;
}

strong {
  font-style: normal;
  font-weight: 500;
}

h1 {
  display: block;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  color: inherit;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

h2 {
  display: block;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  color: inherit;
  font-family: inherit;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

h3 {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

h5 {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;

  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.1;
}

ul {
  padding: 0;
  margin: 0;
  margin-top: 0;
  margin-bottom: 10px;
  list-style: none;

  ul {
    margin-bottom: 0;
  }
}

a {
  background: 0 0;
  color: $color-black;
  cursor: pointer;
  outline: 0;
  text-decoration: none;

  &:hover {
    color: $color-black;
    outline: 0 !important;
    text-decoration: underline;
  }
}

.intro {
  // The paragraph styling from rich text blocks need the styling of intro
  // but we can't set the intro class directly on the paragraph.
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  color: $color-black;

  p {
    font-size: inherit;
    line-height: inherit;
  }

  p:not(:first-child) {
    padding-top: inherit;
  }
}

.rich-text {
  a {
    transition: color 200ms $ease-out-sine;
  }
}

.quote3 {
  margin-top: 0;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 26px;
  text-align: left;
}

.support-text {
  padding: 0;
  margin: 0;
  color: $color-black;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 16px;
}

.hybrid-tag {
  color: #8f9496;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;

  .hybrid-blue {
    color: #0072f0;
  }
}

@include media-breakpoint-up(md) {
  h1 {
    padding-bottom: 16px;
  }

  .hybrid-tag {
    display: inline;
  }
}
