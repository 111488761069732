@use '../../abstracts' as *;

/**
* @description Transitions - Dealer map
*
*/

.side-panel {
  transition: transform 250ms $ease-in-out-cubic;
  will-change: transform;

  &__container {
    transition: transform 250ms $ease-in-out-cubic;
    will-change: transform;
  }
}

.dealer-list {
  @include media-breakpoint-only(xs) {
    &__body {
      transition: all 500ms $ease-in-out-cubic;
    }
  }
}

.dealer-search {
  &__input {
    input {
      transition: background 250ms $ease-out-cubic, box-shadow 250ms $ease-out-cubic 100ms;

      &:active,
      &:focus {
        outline: none;
      }

      &:focus {
        background: $color-white;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
        transition: background 250ms $ease-out-cubic 100ms, box-shadow 250ms $ease-out-cubic;
      }
    }
  }
}
