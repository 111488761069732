@use '../../abstracts' as *;

/**
* @description UI Kit - Navigation - car aside with extra
* spacing to accomodate car range and car aside
*
*/

.car-aside {
  padding: 0 ($bootstrap-column-width * 0.5) 7px;
  margin-top: -18.5px;

  @include media-breakpoint-only(sm) {
    padding: 0 $bootstrap-column-width 7px;
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    padding: 0 7px 0 13px;
    margin-top: 24px;
    margin-left: -8px;
  }
}
