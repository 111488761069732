@use '../../abstracts' as *;

/**
* @description Block - FAQ
*
*/

// Toyota styleguide overrides, because we don't use/have their accordion js code
.collapsible-panel {
  border-bottom: 1px solid $color-light-grey;
  padding-block: 1.6rem;

  &.expanded {
    .icon-chevron {
      transform: rotate(180deg);
    }

    .panel-section {
      margin-top: 2.4rem;
    }
  }

  .panel-section {
    display: block;
    overflow: hidden;
    max-height: 0;
    transition: all 200ms $ease-out-sine;
  }

  .accordion-header-toggle {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 600;

    .icon-chevron {
      --size: 32px;
      --icon: var(--icon-chevron-down);
      --color: currentcolor;

      @include icon;
    }
  }
}

.block-faq {
  padding: $block-padding-major-xs - 6px 0 $block-padding-major-xs;

  &__title {
    margin-bottom: 4px;
  }

  &__category {
    & + .block-faq__category {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: $block-padding-major-sm - 6px 0 $block-padding-major-sm;

    &__title {
      margin-bottom: 22px;
    }

    &__category {
      & + .block-faq__category {
        margin-top: 30px;
      }
    }
  }
}
