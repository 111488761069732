/**
* @description Forms - Workplace appointment
*
*/

.form-appointment {
  .dropdown {
    margin-bottom: 0;

    .icon {
      pointer-events: none;
    }
  }
}
