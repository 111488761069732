@use '../../abstracts' as *;

/**
* @description Block - Form
*
*/

.block-form {
  padding-top: $block-padding-minor-xs;

  // Override block default padding-top set to 0
  > :first-child {
    padding-bottom: $block-padding-minor-xs !important;
  }

  &__title {
    padding-bottom: $block-padding-minor-xs;
  }

  .is-radio-checkbox {
    padding-top: 10px;
  }

  .col-md-6 + .is-radio-checkbox {
    padding-top: 0;
  }

  .is-gdpr-checkbox {
    padding-top: 16px;
    padding-bottom: 1px;
  }

  .form-label {
    display: block;

    &.has-padding {
      padding-bottom: 5px;
    }
  }

  .input-container {
    padding-bottom: 16px;

    label {
      margin-bottom: 6px;

      a {
        transition: color 250ms $ease-in-out-cubic;

        &:active,
        &:hover {
          color: $color-red;
        }
      }
    }

    input,
    > :last-child {
      margin-bottom: 0;
    }

    textarea {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .muted {
      display: block;
      padding-bottom: 10px;
      margin-top: 10px;
      clear: left;
      color: $color-grey-paragraph;
      line-height: 18px;
    }

    .input-warning {
      margin-bottom: 0;
      clear: both;
    }

    &.input-invalid {
      .input-warning {
        margin-top: 0;
        margin-bottom: 5px;
      }

      .pull-left + .input-warning {
        padding-top: 3px;
      }

      .muted {
        margin-top: 0;
      }
    }
  }

  .check + .input-warning {
    padding-top: 2px;
  }

  &__disclaimer {
    display: block;
    padding: 0 0 20px;
    color: $color-grey-paragraph;
  }

  .btn {
    margin-top: 10px;
  }

  @include media-breakpoint-only-xxxs {
    .btn {
      width: 100%;
    }
  }

  @include media-breakpoint-only(xs) {
    .col-md-6 + .is-radio-checkbox {
      margin-top: -5px;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: $block-padding-minor-sm;

    &__title {
      padding-bottom: $block-padding-minor-sm;
    }

    .btn {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: $block-padding-minor-md;
  }
}
