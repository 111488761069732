@use '../../abstracts' as *;

/**
* @description UI - List
*
*/

.list {
  &.is-checklist {
    display: grid;

    .list__item {
      display: flex;
      align-items: flex-start;
      gap: 0 10px;

      svg {
        flex-grow: 0;
        flex-shrink: 0;
      }

      p {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 0;
        margin: 0;
      }
    }
  }

  &.is-large-checklist {
    gap: 12px 0;

    .list__item {
      svg {
        width: 20px;
        height: 20px;
        margin-top: 4px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  &.is-small-checklist {
    gap: 10px 0;

    .list__item {
      svg {
        width: 20px;
        height: 20px;
        margin-top: -2px;
      }

      p {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  &.is-downloads {
    .list__item {
      &:last-of-type {
        padding-bottom: 0;
      }

      @include media-breakpoint-up(sm) {
        padding-bottom: 10px + 2px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    a {
      display: inline-flex;
      align-items: flex-start;
      color: $color-black;
      gap: 0 8px;

      &:last-child {
        padding-bottom: 0;
      }

      .icon {
        flex-shrink: 0;
      }

      span {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 16px;
        line-height: 26px;
      }

      &:hover {
        color: #6c7073;
        text-decoration: none;
      }

      &:active {
        color: $color-black;
      }

      &:focus-visible {
        outline: 2px solid $color-outline;
        outline-offset: 2px;
      }
    }
  }

  &.is-subnav {
    display: inline-flex;
    flex-direction: row;
    margin: 0 auto;

    a {
      position: relative;
      display: block;
      overflow: hidden;
      padding: 0 0 20px;
      color: $color-dark-grey;
      font-size: 16px;
      font-weight: normal;
      line-height: 18px;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $color-red;
        content: '';
        transform: translate3d(0, 100%, 0);
        transition: transform 250ms $ease-in-out-cubic;
      }

      &:focus,
      &:hover {
        text-decoration: none;

        &::after {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .list__item {
      padding: 0 15px;

      &.is-active {
        a {
          font-weight: 500;

          &::after {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .list__item {
        padding: 0 40px;
      }

      a {
        padding: 0 0 41px;
      }
    }
  }

  &.is-icon-list {
    .list__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0;
    }

    i {
      color: $color-dark-grey;
      font-size: 16px;
      line-height: 16px;
    }

    span {
      padding-left: 7px;
      color: $color-grey-paragraph;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &.has-no-wrap-xs {
    display: inline-flex;
    flex-wrap: nowrap;
    margin: 0 auto;

    .list__item {
      padding: 10px;
    }
  }

  &.is-categorynav {
    display: inline-flex;
    flex-direction: row;
    margin: 0 auto;

    a {
      position: relative;
      display: block;
      overflow: hidden;
      padding: 0 0 12px;
      margin-bottom: 50px;
      color: $color-white;
      font-size: 16px;
      font-weight: normal;
      line-height: 18px;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $color-white;
        content: '';
      }

      &:hover,
      &:active {
        text-decoration: none;

        &::after {
          background-color: $color-red;
        }
      }
    }

    .list__item {
      padding: 0 15px;

      &.is-active {
        a {
          font-weight: bold;

          &::after {
            background-color: $color-red;
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .list__item {
        padding: 0 40px;
      }
    }
  }
}
