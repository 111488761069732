@use '../abstracts' as *;

/**
* @description Page - Home
*
*/

.home-page {
  .bg-white + .bg-white {
    padding-top: 0;
  }

  .bg-light-grey + .bg-light-grey {
    padding-top: 0;
  }

  .bg-grey-paragraph + .bg-grey-paragraph {
    padding-top: 0;
  }

  .top-tasks-bar + .block-dealer-intro {
    padding-top: 0;
    padding-bottom: 0;
  }

  .block-taxation + .block-used-cars,
  .block-used-cars + .block-dealer-intro,
  .block-dealer-intro + .block-used-cars {
    @include top-light-grey-border;
  }

  .block-taxation + .block-used-cars {
    padding-top: $block-padding-major-xs - 6px;
  }

  .block-dealer-intro + .block-used-cars {
    @include top-light-grey-border;

    &::before {
      top: ($block-padding-major-xs - 6px) * -1;
    }
  }

  @include media-breakpoint-up(sm) {
    .block-taxation + .block-used-cars {
      padding-top: $block-padding-major-sm - 6px;
      margin-top: -8px;
    }

    .block-dealer-intro + .block-used-cars,
    .block-taxation + .block-used-cars {
      &::before {
        top: ($block-padding-major-sm - 6px) * -1;
      }
    }
  }
}
