@use '../../abstracts' as *;

/**
* @description Block - Review summary
*
*/

.review-summary {
  //

  &__item {
    display: flex;
    align-items: center;
    padding: 5px 0;
    gap: 8px;

    &-label {
      width: 55%;
      flex-grow: 0;
      flex-shrink: 0;
      color: $color-grey-paragraph;
      font-size: 1.6rem;
      line-height: 24px;
    }

    &-bar {
      position: relative;
      width: 30%;
      flex-grow: 0;
      flex-shrink: 0;

      &::before,
      i {
        display: block;
        height: 4px;
        border-radius: 2px;
      }

      &::before {
        position: relative;
        display: block;
        width: 100%;
        background-color: $color-light-grey-hover;
        content: '';
      }

      i {
        position: absolute;
        top: 0;
        left: 0;
        width: attr(data-value);
        border-radius: 2px;
        background-color: $color-dark-grey;
      }
    }

    &-amount {
      flex-grow: 1;
      flex-shrink: 0;
      color: $color-dark-grey;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 13px;
      text-align: right;
    }
  }
}
