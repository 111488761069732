@use '../../abstracts' as *;

/**
* @description Block - Hero
*
* DEVELOPMENT NOTES:
* Padding and spacing may seem weird but using PixelSnap - Johnnny requested exact spacing from
* the top of the page.
*
*/

.block-hero {
  display: block;
  width: 100%;
  max-width: 100vw;

  &__image {
    display: block;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__body {
    margin: 5.4rem auto 4.8rem;

    @include media-breakpoint-up(md) {
      max-width: 66.666%;
    }

    h1,
    h3 {
      text-align: inherit;
    }

    h1 + h3 {
      margin-top: 18px;
    }
  }

  &__title {
    position: relative;
    padding-bottom: 2.4rem;
    margin-top: 0;
    margin-bottom: 2.4rem;
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;

    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 7.9rem;
      height: 3px;
      background: $color-red;
      content: '';
      transform: translateX(-50%);
    }
  }

  &__subtitle {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    text-transform: none;
  }

  // Home page
  &.is-homepage {
    .block-hero {
      &__link {
        transition: $image-hover-focus-transition;
        will-change: transform;

        &:focus,
        &:hover {
          transform: scale3d(1.025, 1.025, 1.025);
        }
      }
    }
  }

  // Default image (used on content pages
  &.is-default {
    padding: $block-padding-major-xs 0 $block-padding-major-xs - 10px;

    h2,
    h3 {
      text-align: center;
    }

    // Adjust so exactly 30px between h2 & h3
    h2 + h3 {
      margin-top: -14px;
    }

    .block-hero {
      &__image {
        img {
          height: auto;
        }
      }
    }
  }

  // Default hero - has no image (as name suggests)
  &.has-no-image {
    padding: $block-padding-major-xs - 6px 0 $block-padding-major-xs;
  }

  // Review, job and team hero
  &.is-review,
  &.is-job,
  &.is-team {
    .block-hero__body {
      text-align: center;
    }
  }

  &.is-review {
    padding: $block-padding-major-xs - 5px 0 $block-padding-minor-xs + 6px;
  }

  &.is-job,
  &.is-team {
    padding: $block-padding-major-xs - 6px 0 $block-padding-minor-xs - 5px;

    h4 {
      padding-bottom: $block-padding-minor-xs - 8px;
      line-height: 27px;
      text-align: center;
    }
  }

  @include media-breakpoint-between-xxs-xs {
    // Home page hero - specify fixed height
    &.is-homepage {
      .block-hero__image {
        overflow: hidden;

        img {
          width: 100%;
          height: 246px;
          object-fit: cover;
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    &.is-review {
      h2 {
        margin-bottom: 12px;
      }

      p {
        padding-top: 9px;
        padding-bottom: 6px;
      }
    }

    &.is-job {
      .social-toolbar {
        flex-direction: column;

        &__icons {
          .icon {
            margin-left: auto;
          }

          span {
            margin-right: auto;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &__background {
      position: relative;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    &.is-homepage {
      position: relative;

      .block-hero__image {
        text-align: center;

        img {
          width: 100%;
          max-height: 533px;
          object-fit: cover;
        }
      }
    }

    &.is-default {
      padding: $block-padding-major-xs 0 0;
    }

    &.has-no-image {
      padding: $block-padding-major-sm - 7px 0 $block-padding-major-sm - 9px;

      h3 {
        margin-bottom: 3px;
      }
    }

    &.is-review {
      padding: $block-padding-major-sm - 6px 0 $block-padding-minor-sm - 3px;

      // TODO: Make car model review subtitle it's own component (if time)
      .block-car-model-reviews__subtitle {
        padding-bottom: 0;
        padding-left: 30px;
      }
    }

    &.is-job,
    &.is-team {
      padding: $block-padding-major-sm - 6px 0 $block-padding-minor-sm - 6px;
    }
  }

  @include media-breakpoint-up(md) {
    &__link {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
