@use '../../abstracts' as *;

/**
* @description UI - Gallery
*
*/

.gallery {
  display: flex;
  flex-wrap: wrap;

  //
  &__link {
    position: relative;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.has-2-images,
  &.has-3-images,
  &.has-4-images,
  &.has-5-images {
    .gallery__link {
      &:last-child {
        position: relative;
        display: block;

        img {
          position: absolute;
          z-index: 1;
        }

        &::before {
          position: absolute;
          z-index: 2;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($color-black, 0.7);
          content: '';
        }

        &::after {
          position: absolute;
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px;
          color: $color-white;
          content: attr(data-label);
          font-size: 16px;
          inset: 0;
          line-height: 26px;
          text-align: left;
          transition: color 250ms $ease-in-out-cubic;
        }

        &:active,
        &:hover {
          &::after {
            color: $color-red;
          }
        }

        @include media-breakpoint-up(sm) {
          flex-grow: 0;
          flex-shrink: 0;

          &::before {
            text-align: center;
          }
        }
      }
    }
  }

  //
  &.has-2-images {
    .gallery {
      &__link {
        display: none;

        &:nth-child(1) {
          display: block;
          width: 50%;
        }

        &:last-child {
          display: block;
          width: 50%;
        }

        @include media-breakpoint-only(xs) {
          &:nth-child(1),
          &:last-child {
            height: 216px;
          }
        }

        @include media-breakpoint-up(sm) {
          &:nth-child(1) {
            width: 66.6666%;
          }

          &:last-child {
            width: 33.3333%;
          }

          &:nth-child(1),
          &:last-child {
            height: 316px;
          }
        }

        @include media-breakpoint-up(md) {
          &:nth-child(1),
          &:last-child {
            height: 405px;
          }
        }
      }
    }
  }

  &.has-3-images,
  &.has-4-images,
  &.has-5-images {
    .gallery__link {
      @include media-breakpoint-only(xs) {
        display: none;

        img {
          position: absolute;
          inset: 0;
        }

        &:nth-child(1) {
          display: block;
          width: 100%;
          padding-bottom: 56.2667%;
          border-bottom: 1px solid $color-white;
        }

        &:nth-child(2) {
          display: block;
          width: 50%;
          padding-bottom: 28.0748%;
          border-right: 1px solid $color-white;
        }

        &:last-child {
          display: block;
          width: 50%;
          padding-bottom: 28.0748%;
        }
      }
    }
  }

  //
  &.has-3-images {
    @include media-breakpoint-up(sm) {
      .gallery__link {
        display: none;

        &:nth-child(1) {
          display: block;
          width: 100%;
          height: 316px;
        }

        &:nth-child(2),
        &:last-child {
          display: block;
          width: 50%;
          height: 216px;
        }
      }

      @include media-breakpoint-up(md) {
        .gallery__link {
          &:nth-child(1) {
            height: 405px;
          }

          &:nth-child(2),
          &:last-child {
            height: 316px;
          }
        }
      }
    }
  }

  //
  &.has-4-images {
    @include media-breakpoint-up(sm) {
      .gallery__link {
        display: none;

        &:nth-child(1) {
          display: block;
          width: 100%;
          height: 316px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:last-child {
          display: block;
          width: 33.333%;
          height: 169px;
        }
      }

      @include media-breakpoint-up(md) {
        .gallery__link {
          &:nth-child(1) {
            height: 405px;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:last-child {
            height: 316px;
          }
        }
      }
    }
  }

  //
  &.has-5-images {
    @include media-breakpoint-up(sm) {
      .gallery__link {
        display: none;

        &:nth-child(1),
        &:nth-child(2) {
          display: block;
          width: 50%;
        }

        &:nth-child(3),
        &:nth-child(4) {
          display: block;
          width: 40%;
        }

        &:last-child {
          display: block;
          width: 20%;
        }
      }
    }
  }
}
