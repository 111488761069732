@use '../../abstracts' as *;

/**
* @description UI Kit - Navigation restyle overrides
*
*/
.navigation {
  position: relative;
  z-index: 1053;
  width: 100%;
  height: 50px;
  background-color: $color-white;

  .menu-level-1 {
    .toggle-collapser {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px calc(12px + var(--safe-area-inset-right)) 16px
        calc(19px + var(--safe-area-inset-left));
      font-size: 1.6rem;
      line-height: 1.8rem;
      transition: color 0.5s ease-out;

      &:hover {
        transition: color 0.5s ease-out;
      }
    }

    .icon-chevron-right {
      margin-top: -2px;
    }
  }

  .menu {
    position: static;
    z-index: 2;
    float: right;

    a {
      display: block;
      text-decoration: none;
    }
  }

  .collapser {
    overflow: hidden;
    max-height: 0;
    background: $color-dark-grey;
    color: $color-white;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .drop-menu {
    position: absolute;
    z-index: 1053;
    top: 50px;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    background-color: $color-dark-grey;
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    transition: opacity 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .container-nav-menu {
    padding-block: 24px;

    &::before,
    &::after {
      display: block;
      width: 100%;
      clear: both;
      content: '';
    }
  }

  .logo {
    position: relative;
    height: 100%;
    margin-left: 10px;
  }

  .toggle-menu {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    margin-top: 6px;
    margin-right: 20px;
    margin-right: calc(4px + var(--safe-area-inset-right));
    background-color: transparent;
    color: currentcolor;
    cursor: pointer;
  }

  .menu-level-2 {
    padding: 0;
    margin-top: 8px;

    .block {
      padding-bottom: 17px;
      border-bottom: 1px solid rgba($color-grey-medium, 0.3);
      margin-bottom: 17px;
      color: $color-white;
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }

  .menu-item-level-2 {
    margin-top: 15px;
    margin-bottom: 15px;

    h6 {
      font-weight: bold;
    }
  }

  .menu-level-3 {
    margin: 0 0 33.7px;

    a {
      color: $color-white;
      font-size: 1.6rem;
      line-height: 1.8rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .menu-item-level-3 {
    margin-bottom: 10.4px;

    a {
      color: currentcolor;
    }
  }

  .toggle-collapser {
    width: 100%;
    padding: 24px 20px 26px;
    border-bottom: 1px solid $color-white-transparent-light;
    clear: both;
    color: currentcolor;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2rem;

    .icon {
      color: currentcolor;
      float: right;
    }
  }

  .car-range {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    gap: 50px 0;

    .price-from {
      color: $color-white;
      font-size: 1.3rem;
    }

    &__item {
      overflow: hidden;
      padding: 8px 12px 12px;
      transition: background-color 150ms $ease-in-sine 150ms;

      &::after {
        transform: scale3d(1, 0.5, 1);
        transform-origin: 50% 100%;
        transition: transform 200ms $ease-in-sine, background-color 150ms $ease-in-sine 150ms;
        will-change: transform, background-color;
      }

      &:hover,
      &:focus {
        background-color: hsl(206deg 3% 44% / 30%);

        &::after {
          border-bottom: 0;
          background-color: $color-red-hover;
          transform: scale3d(1, 1, 1);
          transition: transform 200ms $ease-out-sine, background-color 150ms $ease-in-sine 150ms;
        }
      }
    }
  }

  .header-locked & {
    background-color: $color-dark-grey;
    color: $color-white;

    .menu-level-1 {
      color: #a8aaac;

      :is(.active) {
        color: $color-white;
      }
    }
  }

  &__dealer-text {
    position: absolute;
    right: 50px;
    left: 70px;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    h6 {
      margin: auto;
      color: currentcolor;
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      text-align: center;
    }
  }

  &__dealer-logo {
    display: none;
  }

  .active {
    > .drop-menu {
      height: calc(var(--vh, 1vh) * 100 - 84px);
      max-height: calc(var(--vh, 1vh) * 100 - 84px);
      box-shadow: none;
      opacity: 1;
      pointer-events: all;
    }

    > .collapser {
      height: auto;
      max-height: none;
      opacity: 1;
    }
  }

  .side-extra {
    &:empty {
      display: none;
    }
  }

  &__cta {
    padding: 5px 15px 30px;
  }

  @include media-breakpoint-down(xs) {
    .drop-menu {
      pointer-events: none;
    }

    .menu-item-level-2 {
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    height: 55px;

    .logo {
      margin-left: calc(24px + var(--safe-area-inset-left));
    }

    &__dealer-text {
      height: 55px;
    }

    .toggle-menu {
      margin-top: 9px;
      margin-right: calc(12px + var(--safe-area-inset-right));
    }

    .menu-level-1 {
      .toggle-collapser {
        padding: 25px calc(21px + var(--safe-area-inset-right)) 26px
          calc(23px + var(--safe-area-inset-left));
      }
    }

    .drop-menu {
      top: 55px;
      transition: opacity 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    .menu-level-2 {
      display: flex;
      flex-flow: row wrap;
    }

    .menu-item-level-1.active .toggle-collapser {
      border-bottom: 1px solid #f02;
      color: currentcolor;
    }
  }

  @include media-breakpoint-down(md) {
    .container-nav-menu {
      border-bottom: 1px solid rgb(255 255 255 / 10%);
    }

    .menu-level-1 {
      @media (hover) and (pointer: fine) {
        &:hover .menu-item-level-1:not(:hover) .toggle-collapser:not(:hover) {
          color: #585d5f;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &__cta {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    // Fix scroll locking
    .header-locked & {
      width: calc(100% + var(--scrollbar-width));

      .toggle-menu {
        margin-right: calc(4px + var(--safe-area-inset-right) + var(--scrollbar-width));
      }

      .navigation__dealer-text {
        right: calc(50px + var(--scrollbar-width));
      }

      .drop-menu {
        overflow-y: scroll;
      }
    }
  }

  // Desktop menu
  @include media-breakpoint-up(lg) {
    position: relative;
    height: 70px;
    border-bottom: none;

    .menu-level-1 {
      display: flex;
      margin: 0;
      gap: 0 30px;

      .icon {
        float: none;
      }

      .icon-chevron-right {
        color: $color-grey-paragraph;
        float: right;
      }
    }

    .container-nav-menu {
      padding-top: 0;
      padding-bottom: 50px;
      margin-right: 15px;
      margin-left: 15px;
    }

    .logo {
      padding: 0 20px;
      border-right: none;
      border-left: none;
      margin-right: calc(30px + var(--safe-area-inset-left));
      margin-left: 0;
      float: right;

      .logo-sm {
        margin-bottom: 14px;
      }
    }

    .menu {
      max-width: 70%;
      height: 70px;
      float: left;
      overflow-x: hidden;

      .drop-menu {
        position: static;
        top: 0;
        width: auto;
        max-width: 100%;
        height: inherit;
        margin-right: 30px;
        margin-left: 0;
        background-color: transparent;
        opacity: 1;
        overflow-x: hidden;

        &--with-logo {
          margin-left: 20px;
        }
      }
    }

    .drop-menu {
      top: 70px;
    }

    .menu-level-3 {
      margin-left: 0;
    }

    .collapser {
      position: absolute;
      z-index: 1053;
      inset: 70px 0 auto;
      transition: opacity 300ms $ease-in-out-cubic;
      will-change: opacity;
    }

    .menu-item-level-1 {
      overflow: hidden;
      max-width: initial;

      &.active {
        .collapser {
          max-height: calc(var(--vh, 1vh) * 100 - 104px);
          overflow-y: auto;
        }
      }

      .toggle-collapser {
        display: flex;
        width: auto;
        height: 70px;
        align-items: center;
        padding: 0;
        border-color: transparent;
        color: currentcolor;
        line-height: 16px;
        text-align: center;
        transition: border-color 250ms ease;
        vertical-align: middle;

        &:hover {
          border-color: $color-red-hover;
        }
      }

      .icon-chevron-up,
      .icon-chevron-down,
      .icon-chevron-right {
        display: none;
      }
    }

    .dim {
      position: absolute;
      z-index: 0;
      height: calc(100vh - 70px);
      background-color: $color-transparent-black;
      inset: 70px 0 auto;
      opacity: 0;
      pointer-events: none;
      transition: opacity 300ms $ease-in-out-cubic;
    }

    &.show-bg .dim {
      cursor: pointer;
      opacity: 1;
      pointer-events: all;
    }

    &__dealer-text {
      display: none;
    }

    &__dealer-logo {
      display: flex;
      overflow: hidden;
      max-width: 190px;
      height: 69px;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: $color-white;
      float: left;
      margin-inline: 30px;

      &--text {
        max-width: none;
        padding-inline: 16px;

        h6 {
          font-weight: bold;
          text-align: center;

          a {
            display: block;
            color: $color-red;
            text-decoration: none;
          }
        }
      }

      img {
        max-width: 150px;
        max-height: 41px;
      }

      &.has-large-logo {
        height: auto;
        border-right: 0;
        border-left: 0;

        img {
          max-width: 150px;
          max-height: 69px;
        }
      }
    }

    .menu-level-2 {
      margin-top: 0;
    }

    .menu-item-level-2 {
      margin-top: 24px;

      :last-child {
        margin-bottom: 0;
      }

      .block {
        margin-bottom: 0;
      }
    }

    .menu-item-level-3 {
      margin-top: 14px;
      margin-bottom: 0;

      &:first-of-type {
        margin-top: 17px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .logo {
      margin-right: calc(50px + var(--safe-area-inset-left));
    }

    &__dealer-logo {
      margin-left: 50px;
    }

    .container-nav-menu {
      min-height: 60rem;
      margin-right: 35px;
      margin-left: 35px;
    }
  }
}

.toyota-logo {
  display: block;
  width: 43px;
  height: 29px;
  margin-top: 11px;
  background-color: currentcolor;
  color: currentcolor;
  mask-image: url('/static/toyota/images/logo-toyota.svg');
  mask-repeat: no-repeat;
  mask-size: 100%;

  @include media-breakpoint-up(sm) {
    width: 48px;
    height: 33px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 18px;
  }
}
