@use '../../abstracts' as *;

/**
* @description UI Kit - Navigation - car range with extra
* spacing to accomodate car range and car aside
*
*/

.car-range {
  width: inherit;
  padding: 0 12.5px; // $bootstrap-column-width - this is to match

  &__item {
    position: relative;
    display: flex !important;
    width: calc(50% - #{$bootstrap-column-width * 0.5});
    min-height: 200px;
    flex-direction: column;
    padding: ($bootstrap-column-width * 0.25) 0 ($bootstrap-column-width * 0.5);
    margin: 0 ($bootstrap-column-width * 0.25) $bootstrap-column-width;

    img {
      min-height: 70px;
      margin: auto;
      object-fit: cover;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      border: 0;
      background-color: rgb(168 170 172 / 30%);
      content: '';
    }

    .model,
    .hybrid-tag,
    .price-from {
      display: block !important;
      font-size: 1.3rem;
      line-height: 1.6rem;
      text-align: left;
    }

    .model {
      margin-top: 7px;
      color: $color-white;
      font-weight: 400;
      line-height: 1.8rem;
    }

    .price-from {
      color: $color-white;
    }
  }

  @include media-breakpoint-only(xs) {
    width: calc(100% + 5px);
    padding: 0;
    margin: 0 -2.5px;

    &__item {
      width: calc(50% - 15px);
      margin: 0 7.5px 30px;
    }
  }

  @include media-breakpoint-up(sm) {
    &__item {
      width: calc(33.333% - #{$bootstrap-column-width * 2});
      margin: 0 $bootstrap-column-width $bootstrap-column-width;
    }
  }

  @include media-breakpoint-up(sm) {
    width: calc(100% + 46px);
    padding: 0 23px;
    margin: 20px -23px 0;
  }

  @include media-breakpoint-only(md) {
    width: calc(100% + 45px);
    padding: 0;
    margin: 40px -22.5px 0;
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% + #{$bootstrap-column-width * 2});
    padding: 0;
    margin: 0 $bootstrap-column-width * -1 0;

    &__item {
      width: calc(25% - 48px);
      margin: 0 24px;
    }
  }

  @include media-breakpoint-up(xl) {
    width: calc(100% + 40px);
    min-height: unset;
  }
}
