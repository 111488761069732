@use '../../abstracts' as *;

/**
* @description Item - Job
*
*/

.item-job {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 20px 20px 10px;
  border: 1px solid $color-border-light-grey;
  background-color: $color-white;

  &__body {
    flex-grow: 1;
    flex-shrink: 1;
    padding-bottom: 10px;

    a {
      display: inline-block;
      padding-bottom: 10px;
      color: $color-dark-grey;
      font-size: 19px;
      font-weight: bold;
      line-height: 21px;

      &::before {
        position: absolute;
        display: block;
        content: '';
        inset: 0;
      }
    }

    p {
      display: block;
      padding-bottom: 13px;
      color: $color-grey-paragraph;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__footer {
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 9px;
    border-top: 1px solid $color-border-light-grey;
  }
}
